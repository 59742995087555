import { WalletTypeEnum } from '@libs/backend-common';
import { DefaultTabItem, EmptyState } from '@libs/components';

import { DesertedIcon } from 'src/assets';
import { useFetchPriceQueries, useTranslation } from 'src/hooks';
import { DashboardWalletLoader } from 'src/content-loaders';

import { useAllAssetsTable } from './useAllAssetsTable';
import { allAssetsEmptyStateTitle } from './helper/allAssetsEmptyStateTitle';

import {
  CustomSearchInput,
  TableContainer,
  TableHeaderWrapper,
  CustomTabs,
  Title,
  TabsAndSearchWrapper,
  CustomTable,
} from './styles';

type AllAssetsTableProps = {
  title: string;
  tabs: DefaultTabItem[];
  activeTab: string | number;
  setActiveTab: (val: string | number) => void;
  walletType: WalletTypeEnum;
  shouldHaveExtraColumns: boolean;
  isCrypto?: boolean;
  hasActions?: boolean;
  className?: string;
  shouldCenterLastColumn?: boolean;
};
const AllAssetsTable: React.FC<AllAssetsTableProps> = ({
  title,
  tabs,
  activeTab,
  setActiveTab,
  walletType,
  shouldHaveExtraColumns,
  isCrypto,
  hasActions,
  className,
  shouldCenterLastColumn,
}) => {
  const { triggerRefetchForDashboardPrices } = useFetchPriceQueries();

  const handleOnTabClick = (val: string | number) => {
    setActiveTab(val);
    triggerRefetchForDashboardPrices();
  };

  const { columns, data, searchInputValue, setSearchInputValue, loading } =
    useAllAssetsTable(
      walletType,
      shouldHaveExtraColumns,
      isCrypto,
      hasActions,
      setActiveTab
    );
  const { t } = useTranslation();

  let renderItem;

  const emptyStateTitle = allAssetsEmptyStateTitle(
    searchInputValue,
    walletType
  );

  if (loading) {
    renderItem = <DashboardWalletLoader />;
  } else if (!data.length) {
    renderItem = <EmptyState img={DesertedIcon} title={t(emptyStateTitle)} />;
  } else {
    renderItem = (
      <CustomTable
        data={data}
        columns={columns}
        shouldCenterLastColumn={shouldCenterLastColumn}
        hasStickyHeader={false}
      />
    );
  }

  return (
    <TableContainer className={className}>
      <TableHeaderWrapper>
        <Title type="titleTwo" fontFamily="primaryBold">
          {title}
        </Title>
        <TabsAndSearchWrapper>
          <CustomTabs
            tabs={tabs}
            activeTab={activeTab}
            handleTabClick={handleOnTabClick}
          />
          <CustomSearchInput
            searchInputValue={searchInputValue}
            setSearchInputValue={setSearchInputValue}
          />
        </TabsAndSearchWrapper>
      </TableHeaderWrapper>
      {renderItem}
    </TableContainer>
  );
};
export default AllAssetsTable;
