import { Input } from '@libs/components';
import { useState } from 'react';
import { Disclaimer } from 'src/components';
import { addressNameLength } from 'src/constants';
import { useTranslation } from 'src/hooks';

import { CustomButton, ModalContent, Separator } from '../styles';

export type NewAddressContentProps = {
  setChecked: (val: boolean) => void;

  value: string;
  buttonTitle?: string;
  disclaimerText?: string;
  inputPlaceholder?: string;
  isValueInSavedAddresses?: boolean;
  onButtonClick?: (addressName: string) => void;
};
const NewAddressContent: React.FC<NewAddressContentProps> = ({
  value,
  buttonTitle,
  disclaimerText,
  inputPlaceholder,
  isValueInSavedAddresses,
  onButtonClick,
  setChecked,
}) => {
  const { t } = useTranslation();
  const [addressName, setAddressName] = useState('');

  const submitAddress = () => {
    setChecked(true);
    onButtonClick?.(addressName);
  };

  const isFormValid =
    addressName.length < 1 ||
    addressName.length > addressNameLength ||
    isValueInSavedAddresses;

  const inputErrorMessage =
    addressName.length > addressNameLength
      ? t('validation_max_length', { max: addressNameLength })
      : '';

  return (
    <ModalContent>
      <Input
        disabled
        value={value}
        placeholder={inputPlaceholder}
        errorMessage={
          isValueInSavedAddresses ? t('withdraw_address_already_saved') : ''
        }
      />
      <Separator />
      <Input
        errorMessage={inputErrorMessage}
        value={addressName}
        onChangeText={setAddressName}
        disabled={isValueInSavedAddresses}
        placeholder={t('withdraw_modal_input_placeholder')}
      />
      <Separator />
      {!!disclaimerText && (
        <>
          <Disclaimer description={disclaimerText} />
          <Separator />
        </>
      )}
      <CustomButton
        title={buttonTitle}
        onClick={submitAddress}
        disabled={isFormValid}
      />
    </ModalContent>
  );
};
export default NewAddressContent;
