import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { meQuery } from '@libs/graphql';
import { useApiResponse } from '@libs/hooks';
import { DefaultTabItem } from '@libs/components';
import { WalletInfoItem } from '@libs/types';
import {
  CashTransactionType,
  CountriesEnum,
  FeatureModuleEnum,
  JurisdictionsEnum,
} from '@libs/backend-common';

import { CurrencyType } from 'src/constants';
import { useTranslation } from './useTranslation';
import { useEnabledFeatures } from './useEnabledFeatures';
import { useJurisdictionDetector } from './useJurisdictionDetector';

type OperationTabs = DefaultTabItem & {
  isCrypto: boolean;
};
export type UseDepositWithdrawCurrencySelectionProps = {
  assets: WalletInfoItem[];
  type: CashTransactionType;
  resetStepper: () => void;
};
export const useDepositWithdrawCurrencySelection = ({
  assets,
  type,
  resetStepper,
}: UseDepositWithdrawCurrencySelectionProps) => {
  const { t } = useTranslation();
  const { currentJurisdiction } = useJurisdictionDetector();

  const meRes = useQuery(meQuery);
  const { response } = useApiResponse(meRes);

  const { checkFeature, checkMultipleFeatures, isLoading } =
    useEnabledFeatures();

  const isCryptoAvailable = checkFeature(
    type === CashTransactionType.DEPOSIT
      ? FeatureModuleEnum.CRYPTO_DEPOSIT
      : FeatureModuleEnum.CRYPTO_WITHDRAWAL
  );

  const isFiatAvailable = checkMultipleFeatures(
    type === CashTransactionType.DEPOSIT
      ? [
          FeatureModuleEnum.CARD_DEPOSIT,
          FeatureModuleEnum.CASH_DEPOSIT,
          FeatureModuleEnum.BANK_WIRE_DEPOSIT,
        ]
      : [
          FeatureModuleEnum.CARD_WITHDRAWAL,
          FeatureModuleEnum.CASH_WITHDRAWAL,
          FeatureModuleEnum.BANK_WIRE_WITHDRAWAL,
        ]
  );

  const [searchParams] = useSearchParams();
  const tabItems: OperationTabs[] = useMemo(() => {
    const tabs = [
      {
        id: 1,
        isCrypto: false,
        title: t('common_fiat'),
        disabled:
          !isFiatAvailable.isAvailable ||
          (isFiatAvailable.isAvailable &&
            response?.me?.nationality === CountriesEnum.RU),
      },
      {
        id: 2,
        isCrypto: true,
        title: t('common_crypto'),
        disabled: !isCryptoAvailable.isAvailable,
      },
    ];

    if (currentJurisdiction === JurisdictionsEnum.UA) {
      [tabs[0], tabs[1]] = [tabs[1], tabs[0]];
    }
    return tabs;
  }, [
    isCryptoAvailable.isAvailable,
    isFiatAvailable.isAvailable,
    response?.me?.nationality,
    currentJurisdiction,
    t,
  ]);
  const selectedCurr = searchParams.get('selectedCurrency') as CurrencyType;

  const { fiatTab, cryptoTab } = useMemo(() => {
    const fiatTab = tabItems.find((i) => !i.isCrypto);
    const cryptoTab = tabItems.find((i) => i.isCrypto);

    return { fiatTab, cryptoTab };
  }, [tabItems]);

  const [defaultAsset, setDefaultAsset] = useState<WalletInfoItem>();
  const [activeTab, setActiveTab] = useState<string | number>();

  const isCryptoTabActive = cryptoTab?.id === activeTab;

  useEffect(() => {
    if (!isLoading && !selectedCurr) {
      setActiveTab(
        (isFiatAvailable.isAvailable ? fiatTab : cryptoTab)?.id as number
      );
    }
  }, [
    cryptoTab,
    fiatTab,
    isFiatAvailable.isAvailable,
    isLoading,
    selectedCurr,
  ]);

  useEffect(() => {
    if (selectedCurr) {
      const foundCurr = assets.find((item) => item.code === selectedCurr);
      if (foundCurr) {
        setActiveTab(
          (!foundCurr.isCrypto ? fiatTab?.id : cryptoTab?.id) as number
        );
        setDefaultAsset(foundCurr);
      }
    } else {
      resetStepper();
      setDefaultAsset(undefined);
    }
  }, [
    assets,
    cryptoTab?.id,
    fiatTab?.id,
    resetStepper,
    searchParams,
    selectedCurr,
  ]);

  const setTab = useCallback((val: number | string) => {
    setActiveTab(val);
    setDefaultAsset(undefined);
  }, []);

  return {
    tabItems: tabItems,
    defaultAsset,
    isCryptoTabActive,
    activeTab,
    setActiveTab: setTab,
    setDefaultAsset,
  };
};
