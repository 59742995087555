import { Fragment, useEffect, useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { WalletTypeEnum } from '@libs/backend-common';
import {
  NegativeIcon,
  PositiveIcon,
  TableCell,
  TransparentButton,
  Typography,
} from '@libs/components';
import { getEstimatedAmount } from '@libs/helpers';
import { useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';
import { CurrencyType } from '@libs/types';

import { FavoriteAssetIndicator, WalletActions } from 'src/components';
import { ArrowRightIcon } from 'src/assets';
import { useTranslation, useWalletInfo } from 'src/hooks';
import { PRICES_POLL_INTERVAL } from 'src/constants';

import { getUsdOrEurCurrencySymbol } from 'src/helpers';
import {
  ButtonsAndIconWrapper,
  DetailNavigationButton,
  ButtonsWrapper,
  CustomTypography,
  ChangeRateContainer,
  CurrencyIcon,
  Wrapper,
  CustomTableCell,
  ChangeTitleWrapper,
} from './styles';

export const useAllAssetsTable = (
  walletType: WalletTypeEnum,
  shouldHaveExtraColumns: boolean,
  isCrypto?: boolean,
  hasAction?: boolean,
  setActiveTab?: (val: string | number) => void
) => {
  const {
    loading,
    response,
    filteredData,
    searchInputValue,
    setSearchInputValue,
  } = useWalletInfo({
    pollInterval: PRICES_POLL_INTERVAL,
    walletType,
    isCrypto,
  });
  const navigate = useNavigate();

  const hasQueryBeenCalled = useRef<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (hasQueryBeenCalled.current || hasAction) return;
    if (!loading) {
      hasQueryBeenCalled.current = true;
      if (!response?.getWalletInfo.length && setActiveTab) setActiveTab(2);
    }
  }, [hasQueryBeenCalled, response, hasAction, setActiveTab, loading]);

  const { width } = useDimensions();

  const { colors } = useTheme();

  const shouldShowCostColumn = width > screenSizes.laptopM;

  const columns = useMemo(() => {
    if (width < screenSizes.tablet) {
      return [
        { Header: '', accessor: 'assets' },
        { Header: '', accessor: 'operationButtons' },
      ];
    } else {
      const extraColumns =
        shouldHaveExtraColumns || hasAction
          ? [
              {
                Header: t('all_assets_table_amount'),
                accessor: 'assetAmount',
              },
              ...(shouldShowCostColumn
                ? [
                    {
                      Header: t('all_assets_user_price'),
                      accessor: 'userAssetPrice',
                    },
                  ]
                : []),
            ]
          : [];
      if (hasAction) {
        return [
          {
            Header: t('all_assets_table_asset'),
            accessor: 'assets',
          },
          ...extraColumns,
          {
            Header: t('all_assets_table_operation'),
            accessor: 'operationButtons',
          },
        ];
      }
      return [
        {
          Header: t('all_assets_table_asset'),
          accessor: 'assets',
        },
        ...extraColumns,
        {
          Header: t('all_assets_table_asset_price'),
          accessor: 'price',
        },
        {
          Header: t('table_change'),
          accessor: 'change',
        },
        {
          Header: t('all_assets_table_operation'),
          accessor: 'operationButtons',
        },
      ];
    }
  }, [width, shouldHaveExtraColumns, hasAction, t, shouldShowCostColumn]);

  const data: Record<string, React.ReactNode>[] = useMemo(() => {
    if (!filteredData) return [];
    return filteredData?.reduce((accumulator, asset) => {
      const additionalRouteInfo = `?assetCode=${asset.code}`;
      const hasAssetPriceGrown = asset?.priceChange && +asset.priceChange > 0;

      let ChangeRatePrefix;

      if (asset.priceChange) {
        const priceChangeValue = +asset.priceChange;
        if (priceChangeValue > 0) {
          ChangeRatePrefix = <PositiveIcon />;
        } else if (priceChangeValue < 0) {
          ChangeRatePrefix = <NegativeIcon />;
        } else {
          ChangeRatePrefix = null;
        }
      }

      let assetChangePrice;

      const sholdHidePriceChange =
        !asset?.priceChange ||
        [WalletTypeEnum.ALL_ASSETS, WalletTypeEnum.FAVORITE_ASSETS].includes(
          walletType
        );

      const shouldShowDetailsButton = ![
        CurrencyType.USDT,
        CurrencyType.USDC,
      ].includes(asset.code);

      const assetChangeRate = asset.change
        ? `${asset.change.replace('-', '')}`
        : '- -';

      const ChangeTitle = (
        <ChangeTitleWrapper>
          {ChangeRatePrefix} {assetChangeRate}
        </ChangeTitleWrapper>
      );

      if (sholdHidePriceChange) {
        assetChangePrice = '';
      } else {
        if (asset.priceChange)
          assetChangePrice = hasAssetPriceGrown
            ? `${getUsdOrEurCurrencySymbol()}${getEstimatedAmount(
                asset.priceChange
              )}`
            : `${getUsdOrEurCurrencySymbol()}${getEstimatedAmount(
                asset.priceChange.replace('-', '')
              )}`;
      }

      const handleDetailsPageNavigation = () =>
        navigate(`/asset${additionalRouteInfo}`);

      let changeRateColor;

      const formattedBalance = getEstimatedAmount(asset?.value || 0);

      if (asset?.change) {
        changeRateColor = asset.change.includes('-')
          ? colors.interactive.critical
          : colors.text.success;
      }

      const assetFieldDesc = hasAction
        ? asset.description
        : t('all_assets_table_price', {
            currency: getUsdOrEurCurrencySymbol(),
            amount: asset.price,
          });

      const operationButtonsTitle = t('all_assets_table_price', {
        currency: getUsdOrEurCurrencySymbol(),
        amount: formattedBalance,
      });

      let assets, operationButtons;

      if (width < screenSizes.tablet) {
        assets = (
          <TableCell
            img={<CurrencyIcon src={asset?.iconUrl} />}
            title={asset?.name}
            description={assetFieldDesc}
            secondaryDesc={!hasAction ? ChangeTitle : ''}
            secondaryDescColor={changeRateColor}
          />
        );

        operationButtons = (
          <Fragment key={asset?.code}>
            {!hasAction ? (
              <ButtonsAndIconWrapper
                shouldHideDetailsButton={!shouldShowDetailsButton}
              >
                {shouldHaveExtraColumns && (
                  <CustomTableCell
                    isCrypto={asset?.isCrypto}
                    title={asset?.amount + ' ' + asset?.codeLabel}
                    description={operationButtonsTitle}
                    justifyToLeft={false}
                  />
                )}
                {asset.isCrypto && (
                  <>
                    <FavoriteAssetIndicator
                      assetCode={asset?.code}
                      isFavorite={asset?.isFavorite}
                      walletType={walletType}
                    />
                    {shouldShowDetailsButton && (
                      <TransparentButton onClick={handleDetailsPageNavigation}>
                        <ArrowRightIcon />
                      </TransparentButton>
                    )}
                  </>
                )}
              </ButtonsAndIconWrapper>
            ) : (
              <Wrapper>
                <TableCell
                  title={operationButtonsTitle}
                  description={`${asset?.amount} ${asset?.codeLabel}`}
                />
                <WalletActions
                  isCrypto={asset?.isCrypto || false}
                  currency={asset?.code}
                />
              </Wrapper>
            )}
          </Fragment>
        );
      } else {
        assets = (
          <TableCell
            img={<CurrencyIcon src={asset?.iconUrl} />}
            title={asset?.name}
            description={asset?.codeLabel}
          />
        );
        if (hasAction) {
          operationButtons = (
            <WalletActions
              isCrypto={asset?.isCrypto || false}
              currency={asset?.code}
            />
          );
        } else {
          operationButtons = asset?.isCrypto && (
            <ButtonsWrapper shouldHideDetailsButton={!shouldShowDetailsButton}>
              {shouldShowDetailsButton && (
                <DetailNavigationButton onClick={handleDetailsPageNavigation}>
                  <CustomTypography>{t('common_details')}</CustomTypography>
                </DetailNavigationButton>
              )}

              <FavoriteAssetIndicator
                assetCode={asset?.code}
                isFavorite={asset?.isFavorite}
                walletType={walletType}
                key={asset.code}
              />
            </ButtonsWrapper>
          );
        }
      }

      accumulator.push({
        assets,
        operationButtons,
        assetAmount:
          shouldHaveExtraColumns || hasAction ? (
            <TableCell
              title={`${asset?.amount || 0} ${asset?.codeLabel}`}
              description={
                !shouldShowCostColumn
                  ? t('all_assets_table_price', {
                      currency: getUsdOrEurCurrencySymbol(),
                      amount: formattedBalance,
                    })
                  : ''
              }
            />
          ) : null,
        userAssetPrice: (
          <Typography type="bodyButton" fontFamily="primaryBold">
            {t('all_assets_table_price', {
              currency: getUsdOrEurCurrencySymbol(),
              amount: formattedBalance,
            })}
          </Typography>
        ),
        price: (
          <Typography type="bodyButton" fontFamily="primaryBold">
            {t('all_assets_table_price', {
              currency: getUsdOrEurCurrencySymbol(),
              amount: getEstimatedAmount(asset.price),
            })}
          </Typography>
        ),
        change: (
          <ChangeRateContainer>
            <TableCell
              title={ChangeTitle}
              titleSize="bodyButton"
              titleType="primaryBold"
              titleColor={changeRateColor}
              description={assetChangePrice}
            />
          </ChangeRateContainer>
        ),
      });

      return accumulator;
    }, [] as Record<string, React.ReactNode>[]);
  }, [
    filteredData,
    walletType,
    hasAction,
    t,
    width,
    shouldHaveExtraColumns,
    shouldShowCostColumn,
    navigate,
    colors.interactive.critical,
    colors.text.success,
  ]);

  return { columns, data, searchInputValue, setSearchInputValue, loading };
};
