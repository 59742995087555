import { Typography } from '@libs/components';
import { useState } from 'react';
import { DirectionIcon, LocationIcon } from 'src/assets';
import { useTranslation } from 'src/hooks';
import { useTheme } from 'styled-components';
import CashContactUs from '../cash-contact-us/CashContactUs';
import {
  ContactUsWrapper,
  CustomButton,
  CustomTimeIcon,
  DirectionButton,
  LocatonIconWrapper,
  LocationDetailsContainer,
  LocationWrapper,
  TextWrapper,
  MainContent,
  Map,
  WorkingHoursHeader,
  WorkingHoursWrapper,
  TimeWrapper,
} from './styles';

type LocationModalProps = {
  title?: string;
  description?: string;
  iframeUrl?: string;
  url?: string;
};

const LocationModal: React.FC<LocationModalProps> = ({
  title,
  description,
  iframeUrl,
  url,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

  const handleDirectionClick = () => {
    window.open(url);
  };

  const handleClick = () => {
    setIsVisible(true);
  };

  return (
    <LocationDetailsContainer>
      <MainContent>
        <LocationWrapper>
          <LocatonIconWrapper>
            <LocationIcon />
          </LocatonIconWrapper>
          <TextWrapper>
            <Typography
              type="subHeadline"
              fontFamily="primaryBold"
              color={colors.interactive.brandColor}
            >
              {t(title)}
            </Typography>
            <Typography type="headline" fontFamily="primaryBold">
              {t(description)}
            </Typography>
          </TextWrapper>
        </LocationWrapper>
        <Map
          loading="lazy"
          allowFullScreen={false}
          referrerPolicy="no-referrer-when-downgrade"
          src={iframeUrl}
        />
        <WorkingHoursHeader>
          <CustomTimeIcon />
          <Typography type="headline" fontFamily="primaryBold">
            {t('deposit_cash_working_hours')}
          </Typography>
        </WorkingHoursHeader>
        <WorkingHoursWrapper>
          <Typography type="subHeadline" fontFamily="primaryMedium">
            {t('deposit_cash_monday_friday')}
          </Typography>
          <TimeWrapper>
            <Typography type="subHeadline" fontFamily="primaryMedium">
              {t('deposit_cash_monday_friday_time')}
            </Typography>
          </TimeWrapper>
        </WorkingHoursWrapper>
        <WorkingHoursWrapper>
          <Typography type="subHeadline" fontFamily="primaryMedium">
            {t('deposit_cash_saturday_sunday')}
          </Typography>
          <TimeWrapper>
            <Typography type="subHeadline" fontFamily="primaryMedium">
              {t('deposit_cash_saturday_sunday_time')}
            </Typography>
          </TimeWrapper>
        </WorkingHoursWrapper>
        <DirectionButton
          onClick={handleDirectionClick}
          size="lg"
          icon={<DirectionIcon />}
          title={t('deposit_cash_get_directions')}
          buttonType="secondary"
        />
      </MainContent>

      <ContactUsWrapper>
        <Typography type="subHeadline" fontFamily="primaryBold">
          {t('deposit_cash_contact_us_question')}
        </Typography>
        <CustomButton
          onClick={handleClick}
          title={t('common_contact')}
          size="md"
        />
      </ContactUsWrapper>
      <CashContactUs setIsVisible={setIsVisible} isVisible={isVisible} />
    </LocationDetailsContainer>
  );
};

export default LocationModal;
