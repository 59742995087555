import { useEffect } from 'react';

import { AUTHENTIACOR_LINK } from 'src/constants';

export type NavigatorState = {
  originalLink: string;
  iosLink: string;
  androidLink: string;
};

export const AuthenticatorLink = () => {
  useEffect(() => {
    const ua = navigator.userAgent;

    const isIos =
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    const isAndroid = /android/i.test(ua);

    if (isAndroid) {
      window.location.replace(AUTHENTIACOR_LINK.googlePlay);
    } else if (isIos) {
      window.location.replace(AUTHENTIACOR_LINK.appStore);
    } else {
      window.close();
    }
  }, []);

  return null;
};
