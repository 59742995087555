import styled from 'styled-components';

import { Disclaimer, TransparentButton } from '@libs/components';
import {
  DASHBOARD_NAV_WIDTH,
  RequireKyc,
  DEFAULT_NAV_WITDTH_WITH_PADDING,
} from 'src/components';

type ContentContainerProps = {
  isUnderConstruction?: boolean;
};

export const ContentContainer = styled.section<ContentContainerProps>`
  display: flex;
  flex-direction: column;
  bottom: ${({ isUnderConstruction }) =>
    isUnderConstruction ? '-5rem' : 'initial'};
  position: relative;

  flex: 1;

  height: 100%;
`;

export const ContentWrapper = styled.div`
  flex: 1;

  min-height: calc(100% - 8.3rem);

  margin: 8.3rem 0 8rem;

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  max-width: 100vw;
  ${({ theme }) => {
    const color = theme.colors.surface
      .interactiveDefault as unknown as TemplateStringsArray;

    return theme.media.laptop`
    margin: 8.1rem 0 6rem ${
      DASHBOARD_NAV_WIDTH as unknown as TemplateStringsArray
    }rem;

    background-color: ${color};
    max-width: calc(100vw -  ${
      DEFAULT_NAV_WITDTH_WITH_PADDING as unknown as TemplateStringsArray
    }rem);
  `;
  }};
`;

export const BlurImage = styled.img`
  display: none;
  pointer-events: none;

  width: 58.5rem;
  top: calc(100vh - 58.5rem / 2);
  left: 0;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.low};
  opacity: 0.6;

  ${({ theme }) => theme.media.laptop`
    display: inline;
  `};
`;

export const BlurImageRight = styled(BlurImage)`
  left: unset;
  top: calc(-58.5rem / 2 + 8rem);
  right: calc(-58.5rem / 2 + 8rem);
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex: 1;

  min-height: 100%;
  height: -webkit-fill-available;
`;

export const CustomDisclaimer = styled(Disclaimer)`
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.highest};
  height: 100%;

  max-height: 5.6rem;

  border: none;
  border-radius: unset;

  position: fixed;

  background-color: ${({ theme }) => theme.colors.interactive.secondary};
  .disclaimer-title {
    padding: unset;
    padding-right: 1rem;
  }
  .disclaimer-desc {
    text-align: start;
  }
`;

export const DashboardContentContainer = styled.div`
  display: flex;
  flex: 1;

  width: 100%;

  ${({ theme }) => {
    return theme.media.laptop`  
    justify-content:center;
    `;
  }};
`;

export const JustifiedContent = styled.div`
  display: flex;

  width: 100%;
  height: 100%;
  max-width: 82rem;
  background-color: transparent;

  ${({ theme }) => theme.media.laptop`
    padding: 1.8rem 1rem 0;
    justify-content:center;
  `};

  ${({ theme }) => theme.media.laptopM`
    padding:0;
    padding-top: 1.8rem;
  `};
`;

export const MainContent = styled.div`
  width: 100%;
`;

export const BalanceWrapper = styled.div`
  width: 100%;
  padding: 2.2rem 2.2rem 4.9rem;
  background: ${({ theme }) => theme.colors.surface.gradient};

  ${({ theme }) => {
    const radius = theme.borderRadius
      .mediumHigh as unknown as TemplateStringsArray;
    return theme.media.laptop`
      margin-bottom: 1.7rem;
      padding: 2.6rem 2.9rem 2.7rem;
      border-radius: ${radius};
    `;
  }};
`;

export const NavigationItems = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.7rem;
  justify-content: space-between;

  ${({ theme }) => theme.media.laptop`
    display: none;
  `};
`;

export const NavigationItem = styled(TransparentButton)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 7rem;
  height: 4.8rem;

  margin-bottom: 0.5rem;

  border-radius: ${({ theme }) => theme.borderRadius.highL};
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.26);
  box-shadow: inset 0px -4px 14px rgba(255, 255, 255, 0.25);
`;

export const WalletWrapper = styled.div`
  width: 100%;

  margin-top: -3.2rem;
  ${({ theme }) => theme.media.laptop`
    margin-top:0;
  `};
`;

export const Ads = styled.div`
  display: none;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-color: ${({ theme }) => theme.colors.text.default};

  ${({ theme }) => {
    return theme.media.laptopL`
      display:flex;
      width: 29.2rem;
      height: 34.8rem;
      margin-left: 2.5rem;
  `;
  }};
`;

export const MobileAds = styled.div`
  display: flex;
  height: 17.2rem;
  margin: 2.7rem 1.4rem 0;
  background-color: ${({ theme }) => theme.colors.text.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  ${({ theme }) => {
    return theme.media.laptop`
      margin: 2.7rem 0 0;
    `;
  }};

  ${({ theme }) => {
    return theme.media.laptopL`
      display:none;
    `;
  }};
`;

export const CustomKYC = styled(RequireKyc)`
  margin: 1.2rem 1.6rem;
  box-shadow: ${({ theme }) => theme.shadows.xs};
  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tablet`
    margin: 0 0 1.7rem;
  `}
`;
