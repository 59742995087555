import styled from 'styled-components';

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1.6rem;
`;

export const OptionWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 1.2rem 2rem;

  border: 0.1rem solid
    ${({ theme, isActive }) =>
      isActive
        ? theme.colors.interactive.brandColor
        : theme.colors.borders.default};

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  ${({ theme }) => theme.media.laptop`
    min-width: 39rem;
    gap: 2rem;
  `}
`;

export const OptionAndText = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  svg {
    height: 4rem;
  }

  ${({ theme }) => theme.media.laptop`
    gap: 4rem;
     span {
        width: max-content;
      }
  `}
`;
