import { Typography } from '@libs/components';
import { useNavigate } from 'react-router-dom';
import { DepositIcon, MinusIcon, PlusIcon, WithdrawIcon } from 'src/assets';
import DetailsHeaderMobileLoader from 'src/content-loaders/asset-details-header-mobile/DetailsHeaderMobileLoader';
import { useTranslation } from 'src/hooks';
import { useTheme } from 'styled-components';
import {
  AssetDetailsHeaderMobileNavWrapper,
  AssetDetailsMobileHeaderTitleWrapper,
  AssetDetailsMobileHeaderWrapper,
  AssetDetailsNavButtonWrapper,
  CustomButton,
  Img,
} from './styles';
type AssetDetailsHeaderProps = {
  assetName?: string;
  assetCode?: string;
  icon?: string;
  loading: boolean;
};
export const AssetDetailsMobileHeader: React.FC<AssetDetailsHeaderProps> = ({
  assetCode,
  assetName,
  icon,
  loading,
}) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBuyClick = () => {
    navigate(`/convert/BUY?selectedCurrency=${assetCode}`);
  };
  const handleSellClick = () => {
    navigate(`/convert/SELL?selectedCurrency=${assetCode}`);
  };
  const handleDepositClick = () => {
    navigate(`/deposit?selectedCurrency=${assetCode}`);
  };
  const handleWithdrawClick = () => {
    navigate(`/withdraw?selectedCurrency=${assetCode}`);
  };

  return (
    <AssetDetailsMobileHeaderWrapper>
      {loading ? (
        <DetailsHeaderMobileLoader />
      ) : (
        <AssetDetailsMobileHeaderTitleWrapper>
          <Img src={icon} />
          <Typography type="headline" fontFamily="primaryBold">
            {assetName}
          </Typography>
          <Typography
            type="headline"
            color={colors.text.secondary}
            fontFamily="primaryMedium"
          >
            {assetCode}
          </Typography>
        </AssetDetailsMobileHeaderTitleWrapper>
      )}
      <AssetDetailsHeaderMobileNavWrapper>
        <AssetDetailsNavButtonWrapper>
          <CustomButton
            buttonType="tertiary"
            onClick={handleDepositClick}
            icon={<DepositIcon />}
            size="sm"
          />
          <Typography
            fontFamily="primaryMedium"
            color={colors.text.interactive}
          >
            {t('dashboard_deposit')}
          </Typography>
        </AssetDetailsNavButtonWrapper>
        <AssetDetailsNavButtonWrapper>
          <CustomButton
            buttonType="tertiary"
            onClick={handleWithdrawClick}
            size="sm"
            icon={<WithdrawIcon />}
          />
          <Typography
            fontFamily="primaryMedium"
            color={colors.text.interactive}
          >
            {t('dashboard_withdraw')}
          </Typography>
        </AssetDetailsNavButtonWrapper>
        <AssetDetailsNavButtonWrapper>
          <CustomButton
            buttonType="tertiary"
            onClick={handleBuyClick}
            size="sm"
            icon={<PlusIcon />}
          />
          <Typography
            fontFamily="primaryMedium"
            color={colors.text.interactive}
          >
            {t('dashboard_buy')}
          </Typography>
        </AssetDetailsNavButtonWrapper>
        <AssetDetailsNavButtonWrapper>
          <CustomButton
            buttonType="tertiary"
            onClick={handleSellClick}
            size="sm"
            icon={<MinusIcon />}
          />
          <Typography
            fontFamily="primaryMedium"
            color={colors.text.interactive}
          >
            {t('dashboard_sell')}
          </Typography>
        </AssetDetailsNavButtonWrapper>
      </AssetDetailsHeaderMobileNavWrapper>
    </AssetDetailsMobileHeaderWrapper>
  );
};
