import { FC } from 'react';

import { useTheme } from 'styled-components';

import { Button, EurIcon, StateSetter, Typography } from '@libs/components';
import { JurisdictionsEnum } from '@libs/backend-common';
import { GEL_CURRENCIES, USD_CURRENCIES } from '@libs/types';

import { CardIcon, BankIcon } from 'src/assets';
import { CurrencyType, FiatMethods } from 'src/constants';
import { useTranslation } from 'src/hooks';

import {
  CurrencyToggler,
  InputRadius,
  CircledIcon,
  JurisdictionRenderer,
} from 'src/components';

import {
  CustomCurrencyTogglerWrapper,
  CustomInputRadiusContainer,
  CustomRadio,
  InputWrapper,
  StyledDisclaimer,
} from './styles';

type Feature = {
  isAvailable: boolean;
};

type PickWithdrawFiatMethodProps = {
  fiatMethod?: FiatMethods;
  setFiatMethod: StateSetter<FiatMethods | undefined>;
  setCurrencyTogglerValue: StateSetter<CurrencyType>;
  currencyTogglerValue: CurrencyType;
  isBankWireWithdrawalAvailable: Feature;
  isCardWithdrawalAvailable: Feature;
  isCashWithdrawalAvailable: Feature;
  loading: boolean;
  onClick: () => void;
};

export const PickWithdrawFiatMethod: FC<PickWithdrawFiatMethodProps> = ({
  fiatMethod,
  setFiatMethod,
  currencyTogglerValue,
  setCurrencyTogglerValue,
  isBankWireWithdrawalAvailable,
  isCardWithdrawalAvailable,
  isCashWithdrawalAvailable,
  loading,
  onClick,
}) => {
  const { t } = useTranslation();

  const isGelActive = GEL_CURRENCIES.includes(currencyTogglerValue);
  const isUsdActive = USD_CURRENCIES.includes(currencyTogglerValue);

  const bankTransferFee = isUsdActive
    ? t('bank_transfer_fee_USD')
    : t('bank_transfer_fee');

  const handleSetFiatMethod = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (!e.currentTarget.id) return;
    setFiatMethod(e.currentTarget.id as FiatMethods);
  };

  const handleSetCurrencyTogglerValue = (val: CurrencyType) => {
    setCurrencyTogglerValue(val);
    setFiatMethod(undefined);
  };

  const { colors } = useTheme();

  return (
    <>
      <Typography
        type="subHeadline"
        fontFamily="primaryBold"
        color={colors.text.default}
      >
        {t('choose_currency')}
      </Typography>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
        <CustomCurrencyTogglerWrapper>
          <CurrencyToggler
            activeCurrency={currencyTogglerValue}
            onChange={handleSetCurrencyTogglerValue}
          />
        </CustomCurrencyTogglerWrapper>
      </JurisdictionRenderer>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
        <InputWrapper>
          <InputRadius>
            <CustomRadio
              testId="radioWire"
              title={t('currency_eur')}
              checked={true}
              icon={<EurIcon />}
            />
          </InputRadius>
        </InputWrapper>
      </JurisdictionRenderer>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
        <StyledDisclaimer description={t('withdraw_fiat_disclaimer')} />
      </JurisdictionRenderer>
      <Typography
        type="subHeadline"
        fontFamily="primaryBold"
        color={colors.text.default}
      >
        {t('choose_method')}
      </Typography>

      <CustomInputRadiusContainer>
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
          <InputRadius bottomRadiusFlat={true}>
            <CustomRadio
              testId="radioWire"
              title={t('bank_transfer')}
              description={bankTransferFee}
              checked={fiatMethod === FiatMethods.WIRE}
              disabled={!isBankWireWithdrawalAvailable.isAvailable}
              icon={
                <CircledIcon color={colors.interactive.secondaryDisabled}>
                  <BankIcon />
                </CircledIcon>
              }
              id={FiatMethods.WIRE}
              onClick={handleSetFiatMethod}
            />
          </InputRadius>

          <InputRadius topRadiusFlat={true} bottomRadiusFlat={true}>
            <CustomRadio
              testId="radioCard"
              id={FiatMethods.CARD}
              title={t('bank_card_transfer')}
              description={t('bank_card_withdrawal_fee')}
              icon={
                <CircledIcon color={colors.interactive.secondaryDisabled}>
                  <CardIcon />
                </CircledIcon>
              }
              // disabled
              // checked={false}
              disabled={isUsdActive || !isCardWithdrawalAvailable.isAvailable}
              checked={fiatMethod === FiatMethods.CARD && !isUsdActive}
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
          <InputRadius topRadiusFlat={true}>
            <CustomRadio
              testId="radioCash"
              id={FiatMethods.CASH}
              title={t('bank_cash_transfer')}
              description={t('bank_cash_transfer_fee')}
              icon={
                <CircledIcon color={colors.interactive.secondaryDisabled}>
                  <CardIcon />
                </CircledIcon>
              }
              // disabled
              // checked={false}
              disabled={isGelActive || !isCashWithdrawalAvailable.isAvailable}
              checked={fiatMethod === FiatMethods.CASH && !isGelActive}
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
        </JurisdictionRenderer>
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
          <InputRadius>
            <CustomRadio
              testId="radioWire"
              title={t('bank_transfer_sepa')}
              description={bankTransferFee}
              checked={fiatMethod === FiatMethods.SEPA}
              disabled={!isBankWireWithdrawalAvailable.isAvailable}
              icon={
                <CircledIcon color={colors.interactive.secondaryDisabled}>
                  <BankIcon />
                </CircledIcon>
              }
              id={FiatMethods.SEPA}
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
        </JurisdictionRenderer>
      </CustomInputRadiusContainer>

      <Button
        testId="fiatBtn"
        disabled={!fiatMethod || !currencyTogglerValue}
        onClick={onClick}
        className="start_withdrawal start_withdrawal_fiat"
        title={t('common_continue')}
        isLoading={loading}
      />
    </>
  );
};

export default PickWithdrawFiatMethod;
