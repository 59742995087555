import { useQuery } from '@apollo/client';
import { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { meQuery } from '@libs/graphql';
import { useApiResponse } from '@libs/hooks';
import { JurisdictionsEnum } from '@libs/backend-common';
import { Typography, CloseIcon } from '@libs/components';

import { bitnetLogoSmall, CrexoneMobileLogo } from 'src/assets';
import { useJurisdictionDetector } from 'src/hooks';
import {
  BackBtn,
  BitnetLogo,
  CustomImgCont,
  CustomBackBtnImg,
  CustomTransactionsCont,
  CustomTransactionHeader,
} from './styles';

export type TransactionsProp = PropsWithChildren & {
  headTitle?: string;
  onClose?: () => void;
  onGoBack?: () => void;
  isBackButtonVisible?: boolean;
};
const TransactionLayout: React.FC<TransactionsProp> = ({
  headTitle,
  children,
  isBackButtonVisible = true,
  onGoBack,
  onClose,
}) => {
  const navigate = useNavigate();
  const { colors } = useTheme();

  const meRes = useQuery(meQuery);
  const { loading } = useApiResponse(meRes);

  const handleGoBack = () => {
    if (!isBackButtonVisible) return;

    onGoBack?.();
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate('/');
    }
  };
  const { currentJurisdiction } = useJurisdictionDetector();
  const IS_GLOBAL_JURISDICTION = currentJurisdiction === JurisdictionsEnum.UA;

  if (loading) return null;

  return (
    <CustomTransactionsCont>
      <CustomTransactionHeader>
        <BitnetLogo
          onClick={handleClose}
          src={IS_GLOBAL_JURISDICTION ? CrexoneMobileLogo : bitnetLogoSmall}
          alt="Bitnet"
        />
        <BackBtn hasCursor={isBackButtonVisible} onClick={handleGoBack}>
          {isBackButtonVisible && (
            <CustomBackBtnImg data-testid="transaction-back-btn" />
          )}
        </BackBtn>
        <Typography as="h2" type="headline" fontFamily="primaryBold">
          {headTitle}
        </Typography>
        <CustomImgCont onClick={handleClose}>
          <CloseIcon color={colors.text.default} />
        </CustomImgCont>
      </CustomTransactionHeader>
      {children}
    </CustomTransactionsCont>
  );
};
export default TransactionLayout;
