import { Button } from '@libs/components';
import { TimeIcon } from 'src/assets';
import styled from 'styled-components';

export const LocationDetailsContainer = styled.div`
  position: relative;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1.1rem 2.2rem 0;
`;

export const LocationWrapper = styled.div`
  display: flex;

  margin-bottom: 2.1rem;
`;

export const LocatonIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 5rem;
  height: 5rem;

  margin-right: 1rem;

  border-radius: ${({ theme }) => theme.borderRadius.highest};

  background-color: ${({ theme }) => theme.colors.interactive.secondary};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Map = styled.iframe`
  margin-bottom: 2.2rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  ${({ theme }) => theme.media.laptop`
    width: 39rem;
    height: 20rem;
  `};
`;

export const WorkingHoursHeader = styled.div`
  display: flex;

  margin-bottom: 1.4rem;
`;

export const CustomTimeIcon = styled(TimeIcon)`
  margin-right: 1.1rem;
`;

export const WorkingHoursWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  margin-bottom: 0.9rem;
`;
export const TimeWrapper = styled.div`
  width: 12rem;
  text-align: left;
`;

export const DirectionButton = styled(Button)`
  margin-top: 1.7rem;
  margin-bottom: 2.8rem;

  border-color: ${({ theme }) => theme.colors.interactive.brandColor};

  ${({ theme }) => theme.media.tablet`
    margin-bottom: 3.2rem;
  `};
`;

export const ContactUsWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  width: 100%;

  padding: 2rem;

  border-top-left-radius: ${({ theme }) => theme.borderRadius.high};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.high};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.tablet`
    padding: 2.4rem 2.6rem;
  `};
`;

export const CustomButton = styled(Button)`
  width: 40%;

  margin-left: 1rem;
`;
