import { SearchInput } from '@libs/components';
import styled from 'styled-components';

export const SearchInputContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  padding: 1.6rem;

  box-shadow: ${({ theme }) => theme.shadows.md};
  z-index: ${({ theme }) => theme.zIndex.high};
  margin-bottom: 1rem;
`;
export const CustomSearchInputTitle = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tablet`
  display:flex;
  `};
`;

export const CustomSearchInput = styled(SearchInput)`
  height: 5.4rem;
  border-radius: ${({ theme }) => theme.borderRadius.highL};
`;

export const CloseIconWrapper = styled.div`
  width: fit-content;
  padding: 1.1rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
`;

export const EmptyDiv = styled.div``;
