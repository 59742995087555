import { Suspense, useCallback, useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';

import { meQuery } from '@libs/graphql';
import { JurisdictionsEnum } from '@libs/backend-common';
import { screenSizes } from '@libs/theme';

import {
  DashboardNav,
  DashboardHeader,
  DashboardMobileNav,
  JurisdictionRenderer,
  AdvertismentNotifications,
  RegisterSuccess,
} from 'src/components';
import { TOKEN_KEY } from 'src/constants';
import { DashboardBlurImg } from 'src/assets';
import { setOnUnauthorized } from 'src/setup';
import { TicketDataProvider } from 'src/contexts';
import { getPlatformUnderConstructionStatusQuery } from 'src/gql';

import {
  useApiResponse,
  useAuth,
  useGetTicketLiveData,
  useRenderObjectByTheme,
  POP_UP_MODAL_PARENT_ID,
  useTranslation,
  useDimensions,
  useModal,
  usePopUpModal,
} from 'src/hooks';

import {
  BlurImage,
  BlurImageRight,
  ContentContainer,
  ContentWrapper,
  CustomDisclaimer,
  DashboardContainer,
} from './styles';

const Dashboard = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loading } = useQuery(meQuery, { fetchPolicy: 'network-only' });
  const { response } = useApiResponse({ data, loading });

  const me = useQuery(meQuery);

  const { response: meResponse } = useApiResponse(me);

  const [searchParams, setSearchParams] = useSearchParams();

  const { width } = useDimensions();

  const [isNewlyRegistered] = useState(searchParams.get('isNewlyRegistered'));

  const isGreaterThanLaptop = width > screenSizes.laptop;

  const DashboardBlurImage = useRenderObjectByTheme(
    undefined,
    DashboardBlurImg
  );

  const onLogout = useCallback(() => {
    navigate('/login', {
      replace: true,
    });
  }, [navigate]);

  const { logout, checkTokenValidity } = useAuth(onLogout);

  const { getTicketLiveData, liveData, closeTicketLiveData } =
    useGetTicketLiveData();

  useEffect(() => {
    let source: EventSource;
    const token = localStorage.getItem(TOKEN_KEY);
    if (checkTokenValidity(token) && response) {
      source = getTicketLiveData(response.me.email);
    }
    return () => {
      if (source) {
        closeTicketLiveData(source);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    setOnUnauthorized(logout);

    return () => setOnUnauthorized(() => ({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [fetchStatus, { data: statusData }] = useLazyQuery(
    getPlatformUnderConstructionStatusQuery,
    { fetchPolicy: 'network-only' }
  );
  const { response: statusRes } = useApiResponse({
    data: statusData,
  });

  useEffect(() => {
    fetchStatus();
  }, [location.pathname, fetchStatus]);

  const shouldPopUpBeOpened = !!isNewlyRegistered && isGreaterThanLaptop;

  const { Dialog, openModal: openDialog, closeModal: closeDialog } = useModal();

  const {
    PopUpModal,
    openModal: openPopUp,
    closeModal: closePopUp,
  } = usePopUpModal(shouldPopUpBeOpened);

  useEffect(() => {
    const hasChosenNotificationOptions =
      typeof meResponse?.me.agreedToMarketingMessages === 'boolean';

    if (isNewlyRegistered) {
      searchParams.delete('isNewlyRegistered');
      setSearchParams(searchParams);
    } else if (meResponse && !hasChosenNotificationOptions) {
      if (isGreaterThanLaptop) {
        openPopUp();
      } else {
        openDialog();
      }
    }
  }, [
    isNewlyRegistered,
    meResponse,
    openPopUp,
    openDialog,
    isGreaterThanLaptop,
    searchParams,
    setSearchParams,
    location.pathname,
  ]);

  const isUnderConstruction = statusRes?.getPlatformUnderConstructionStatus;

  return (
    <TicketDataProvider value={liveData}>
      <div id={POP_UP_MODAL_PARENT_ID} />
      {isUnderConstruction && (
        <CustomDisclaimer
          title="⚠️"
          boldDescription={t('platform_update_disclaimer')}
          hasIcon={false}
        />
      )}
      <JurisdictionRenderer
        acceptedJurisdictions={[JurisdictionsEnum.GE, JurisdictionsEnum.EU]}
      >
        <Dialog title={t('registration_ads_title')}>
          <AdvertismentNotifications closeModal={closeDialog} />
        </Dialog>
        <PopUpModal>
          <AdvertismentNotifications closeModal={closePopUp} />
        </PopUpModal>
      </JurisdictionRenderer>
      <RegisterSuccess
        openDialog={openDialog}
        isNewlyRegistered={isNewlyRegistered}
      />
      <DashboardContainer data-cy="dashboard">
        <DashboardNav
          testId="dashboardNav"
          isUnderConstruction={isUnderConstruction}
        />
        <ContentContainer isUnderConstruction={isUnderConstruction}>
          <DashboardHeader
            testId="dashboardHeader"
            isUnderConstruction={isUnderConstruction}
          />
          <ContentWrapper data-cy="dashboardContentWrapper">
            <JurisdictionRenderer
              acceptedJurisdictions={[JurisdictionsEnum.UA]}
            >
              <BlurImage src={DashboardBlurImage} />
              <BlurImageRight src={DashboardBlurImage} />
            </JurisdictionRenderer>

            {/* NOTE: Dashboard's contents render here*/}
            <Suspense fallback={<></>}>
              <Outlet />
            </Suspense>
          </ContentWrapper>
          <DashboardMobileNav testId="dashboardMobleNav" />
        </ContentContainer>
      </DashboardContainer>
    </TicketDataProvider>
  );
};

export default Dashboard;
