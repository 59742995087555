import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useApiResponse } from '@libs/hooks';
import { CurrencyType, PartialAsset } from '@libs/types';
import { CurrencyIcons, SelectData } from '@libs/components';

import {
  getSupportedAssetsForLoanQuery,
  GetSupportedAssetsForLoanResponse,
} from 'src/gql';
import { useCurrencyIcons } from 'src/hooks';

const mapExchangeCurrency = (
  item: PartialAsset,
  icons: CurrencyIcons
): SelectData => ({
  value: item.code,
  label: item.code,
  icon: icons[item.code as CurrencyType],
});

export const useGetSupportedAssetsForLoan = () => {
  const [loanAssets, setLoanAssetsOption] = useState<SelectData[]>([]);
  const [collateralAssets, setCollateralAssetsOption] = useState<SelectData[]>(
    []
  );
  const currencyIcons = useCurrencyIcons('3.5rem');

  const onExchangeSettingsSuccess = (
    data: GetSupportedAssetsForLoanResponse
  ) => {
    const loanAssetsOption = data.getSupportedAssetsForLoan.loanAssets.map(
      (val) => mapExchangeCurrency(val, currencyIcons)
    );
    const collateralAssetsOption =
      data.getSupportedAssetsForLoan.collateralAssets.map((val) =>
        mapExchangeCurrency(val, currencyIcons)
      );

    setLoanAssetsOption(loanAssetsOption);
    setCollateralAssetsOption(collateralAssetsOption);
  };

  const res = useQuery(getSupportedAssetsForLoanQuery);
  useApiResponse(res, onExchangeSettingsSuccess);

  return {
    loanAssets,
    collateralAssets,
    setCollateralAssetsOption,
    setLoanAssetsOption,
  };
};
