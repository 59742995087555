import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const DirectionIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId = 'depositIcon',
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.8694 3.50483L12.5832 14.092C11.7968 16.6054 8.27185 16.6475 7.44329 14.1481L6.46025 11.2416C6.19342 10.4412 5.56146 9.79532 4.76098 9.54258L1.83993 8.55969C-0.645764 7.73126 -0.603634 4.1788 1.91015 3.42057L12.4989 0.120867C14.5774 -0.510992 16.5294 1.44075 15.8694 3.50483Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default DirectionIcon;
