import { SelectData } from '@libs/components';

import { useTranslation } from 'src/hooks';
import {
  TransactionFiltersType,
  TransactionMultiSelectFilterValues,
} from 'src/types';

import { MultiSelectFilterDataType } from 'src/constants';
import { CustomFilter } from '../styles';

type TransactionMultiSelectFilterProps =
  MultiSelectFilterDataType<TransactionMultiSelectFilterValues> & {
    onFiltersSubmit: (newValue?: TransactionFiltersType) => void;
    onFilterClear: () => void;
    isFocused: boolean;
    handleFocus: () => void;
    handleBlur: () => void;
    options: SelectData[];
    onOutsideClick?: () => void;
    renderOptionHeader?: (closeSelect: () => void) => JSX.Element;
    JustifyToRight?: boolean;
  };

export const TransactionMultiSelectFilter: React.FC<
  TransactionMultiSelectFilterProps
> = ({
  placeholder,
  textAfterSelection,
  selectedValues,
  handler,
  onFiltersSubmit,
  onFilterClear,
  options,
  onOutsideClick,
  renderOptionHeader,
  searchInputValue,
  JustifyToRight,
}) => {
  const { t } = useTranslation();

  return (
    <CustomFilter
      renderOptionHeader={renderOptionHeader}
      placeholder={placeholder}
      textAfterSelection={textAfterSelection}
      selectedValues={selectedValues}
      onChange={handler}
      onFilterClear={onFilterClear}
      onFiltersSubmit={onFiltersSubmit}
      clearButtonText={t('common_clear')}
      filterButtonText={t('common_filter')}
      options={options}
      emptyValueText={t('common_all')}
      onOutsideClick={onOutsideClick}
      searchInputValue={searchInputValue}
      JustifyToRight={JustifyToRight}
    />
  );
};
