import { useCallback, useRef } from 'react';
import { useTheme } from 'styled-components';

import { CloseIcon, Typography } from '@libs/components';
import { useOutsideClick } from '@libs/hooks';

import { PhoneIcon, TelegramIcon } from 'src/assets';
import { useTranslation } from 'src/hooks';
// import { landingUrl, TOKEN_KEY } from 'src/constants';

import {
  ContactIconWrapper,
  ContactTextWrapper,
  ContactUsCustomComponent,
  // ContactWrapper,
  IconWrapper,
  CustomLink,
} from './styles';

type CashContactUsProps = {
  isVisible: boolean;
  setIsVisible: (som: boolean) => void;
};

export const BITNET_PHONE_NUMBER = '+995 322 050022';
export const BITNET_TELEGRAM_LINK = 'https://t.me/bitnetge';

const CashContactUs: React.FC<CashContactUsProps> = ({
  isVisible,
  setIsVisible,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  // const { selectedTheme } = useThemeMode();
  // const navigate = useNavigate();
  // const { checkTokenValidity } = useAuth();
  // const token = localStorage.getItem(TOKEN_KEY);
  // const isTokenValid = checkTokenValidity(token);

  const ref = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);
  useOutsideClick(ref, handleOutsideClick);

  // const additionalRouteQuery = `?selectedLangCode=${selectedLanguageCode}&selectedTheme=${selectedTheme}`;
  // const handleClick = () => {
  //   if (isTokenValid) {
  //     navigate('/ticket');
  //   } else {
  //     window.open(`${landingUrl}/contact`, `${additionalRouteQuery}`);
  //   }
  // };

  return (
    <ContactUsCustomComponent isVisible={isVisible} ref={ref}>
      {isVisible && (
        <>
          <IconWrapper onClick={handleOutsideClick}>
            <CloseIcon color={colors.text.default} />
          </IconWrapper>
          <CustomLink to={'tel:' + BITNET_PHONE_NUMBER}>
            <ContactIconWrapper>
              <PhoneIcon />
            </ContactIconWrapper>
            <ContactTextWrapper>
              <Typography
                type="bodyButton"
                fontFamily="primaryBold"
                color={colors.interactive.brandColor}
              >
                {t('common_call_us')}
              </Typography>
              <Typography
                type="subHeadline"
                fontFamily="primaryBold"
                color={colors.text.default}
              >
                {BITNET_PHONE_NUMBER}
              </Typography>
            </ContactTextWrapper>
            <div />
          </CustomLink>
          <CustomLink to={BITNET_TELEGRAM_LINK}>
            <ContactIconWrapper>
              <TelegramIcon />
            </ContactIconWrapper>
            <ContactTextWrapper>
              <Typography
                type="bodyButton"
                fontFamily="primaryBold"
                color={colors.interactive.brandColor}
              >
                {t('common_write_us')}
              </Typography>

              <Typography
                type="subHeadline"
                fontFamily="primaryBold"
                color={colors.text.default}
              >
                {BITNET_TELEGRAM_LINK}
              </Typography>
            </ContactTextWrapper>
            <div />
          </CustomLink>
          {/* <ContactWrapper onClick={handleClick}>
            <ContactIconWrapper>
              <SmsIcon />
            </ContactIconWrapper>
            <ContactTextWrapper>
              <Typography type="subHeadline" fontFamily="primaryBold">
                {t('common_contact_support')}
              </Typography>
            </ContactTextWrapper>
            <div />
          </ContactWrapper> */}
        </>
      )}
    </ContactUsCustomComponent>
  );
};

export default CashContactUs;
