import { ApolloError, useMutation } from '@apollo/client';

import { useApiResponse } from 'src/hooks';
import { useCallback } from 'react';
import { payWithCardMutation, PayWithCardResponse } from 'src/gql';

export const useDepositCard = () => {
  const handlePayWithCardSuccess = useCallback((data: PayWithCardResponse) => {
    const status = data.payWithCard.redirectUrl;
    if (status) {
      window.location.href = data.payWithCard.redirectUrl;
    } else {
      // NOTE: custom function provided from mobile app's WebView to indicate successful verification
      window?.onAddCardErrorForRN?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    handleDepositCard,
    {
      data: payWithCardData,
      loading: payWithCardLoading,
      error: payWithCardError,
    },
  ] = useMutation(payWithCardMutation);

  const { loading: payWithCardLoadingState, errorMessage } = useApiResponse<
    PayWithCardResponse,
    ApolloError
  >(
    {
      data: payWithCardData,
      errors: payWithCardError,
      loading: payWithCardLoading,
    },
    handlePayWithCardSuccess
  );

  return { handleDepositCard, payWithCardLoadingState, errorMessage };
};
