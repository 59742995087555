import { SelectData } from '@libs/components';
import { CurrencyType } from 'src/constants';

export const handleDefaultCurrencySelection = <T extends SelectData>(
  options: T[],
  currency?: CurrencyType
) => {
  if (!currency) {
    return options[0];
  }
  const foundCurr = options.find((item) => item.value === currency);
  if (foundCurr) {
    return foundCurr;
  } else {
    return options[0];
  }
};
