import { FC } from 'react';

import { Toggle, Typography } from '@libs/components';

import { TwoFactorAuthType } from '@libs/types';

import { useTwoFaOptions } from './hooks';

import { OptionAndText, OptionWrapper, Options } from './styles';

interface TwoFaOptionsProps {
  handleTogglerClick?: (type: TwoFactorAuthType, isChecked: boolean) => void;
}

const TwoFaOptions: FC<TwoFaOptionsProps> = ({ handleTogglerClick }) => {
  const { options } = useTwoFaOptions(handleTogglerClick);

  return (
    <Options>
      {options.map(({ icon, title, isChecked, handleClick, testId }, index) => (
        <OptionWrapper key={index} isActive={isChecked}>
          <OptionAndText>
            {icon}
            <Typography type="bodyButton" fontFamily="primaryBold">
              {title}
            </Typography>
          </OptionAndText>
          <Toggle
            checked={isChecked}
            onChangeValue={handleClick}
            testId={testId}
          />
        </OptionWrapper>
      ))}
    </Options>
  );
};

export default TwoFaOptions;
