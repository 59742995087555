import { FC } from 'react';

import { useQuery } from '@apollo/client';

import { Button, EurIcon, Typography, StateSetter } from '@libs/components';
import { JurisdictionsEnum } from '@libs/backend-common';

import { CardIcon, BankIcon } from 'src/assets';

import { CurrencyType, FiatMethods } from 'src/constants';
import {
  CurrencyToggler,
  InputRadius,
  CircledIcon,
  JurisdictionRenderer,
} from 'src/components';

import { useApiResponse } from '@libs/hooks';
import { useTheme } from 'styled-components';
import { meQuery } from '@libs/graphql';
import { useTranslation, useJurisdictionDetector } from 'src/hooks';

import {
  CustomCurrencyTogglerWrapper,
  CustomInputRadiusContainer,
  CustomRadio,
  StyledDisclaimer,
  InputWrapper,
} from './styles';

type Feature = {
  isAvailable: boolean;
};

type PickDepositFiatMethodProps = {
  fiatMethod?: FiatMethods;
  setFiatMethod: StateSetter<FiatMethods | undefined>;
  setCurrencyTogglerValue: StateSetter<CurrencyType | undefined>;
  currencyTogglerValue?: CurrencyType;
  isBankWireDepositAvailable: Feature;
  isCardDepositAvailable: Feature;
  onClick: () => void;
  isCashDepositAvailable: Feature;
  loading: boolean;
};

export const PickDepositFiatMethod: FC<PickDepositFiatMethodProps> = ({
  setFiatMethod,
  fiatMethod,
  setCurrencyTogglerValue,
  currencyTogglerValue,
  isBankWireDepositAvailable,
  isCardDepositAvailable,
  isCashDepositAvailable,
  loading,
  onClick,
}) => {
  const meRes = useQuery(meQuery);

  const { response } = useApiResponse(meRes);
  const { colors } = useTheme();

  const { currentJurisdiction } = useJurisdictionDetector();

  const isJurisdictionEu = currentJurisdiction === JurisdictionsEnum.EU;

  const { t } = useTranslation();

  const currencyTitle = isJurisdictionEu
    ? 'selected_currency'
    : 'choose_currency';

  const isCardDepositEnabled = response?.me.isCardDepositEnabled;

  const handleSetCurrencyTogglerValue = (val: CurrencyType) => {
    setCurrencyTogglerValue(val);
    setFiatMethod(undefined);
  };
  const handleSetFiatMethod = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (!e.currentTarget.id) return;
    setFiatMethod(e.currentTarget.id as FiatMethods);
  };
  const nextStep = () => {
    onClick();
  };

  const depositWithCardDescription = !isCardDepositEnabled
    ? 'contact_to_support'
    : 'bank_card_deposit_fee';
  const bankCardDisabledStatus =
    currencyTogglerValue === CurrencyType.BUSD ||
    !isCardDepositAvailable.isAvailable ||
    !isCardDepositEnabled;

  return (
    <>
      <Typography
        type="subHeadline"
        fontFamily="primaryBold"
        color={colors.text.default}
      >
        {t(currencyTitle)}
      </Typography>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
        <CustomCurrencyTogglerWrapper>
          <CurrencyToggler
            activeCurrency={currencyTogglerValue}
            onChange={handleSetCurrencyTogglerValue}
          />
        </CustomCurrencyTogglerWrapper>
      </JurisdictionRenderer>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
        <InputWrapper>
          <InputRadius>
            <CustomRadio
              testId="radioWire"
              title={t('currency_eur')}
              checked={true}
              icon={<EurIcon />}
            />
          </InputRadius>
        </InputWrapper>
      </JurisdictionRenderer>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
        <StyledDisclaimer description={t('deposit_fiat_disclaimer')} />
      </JurisdictionRenderer>
      <Typography
        type="subHeadline"
        fontFamily="primaryBold"
        color={colors.text.default}
      >
        {t('choose_method')}
      </Typography>
      <CustomInputRadiusContainer>
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
          <InputRadius bottomRadiusFlat={true}>
            <CustomRadio
              testId="radioWire"
              title={t('bank_transfer')}
              description={t('bank_transfer_fee')}
              checked={fiatMethod === FiatMethods.WIRE}
              disabled={!isBankWireDepositAvailable.isAvailable}
              icon={
                <CircledIcon color={colors.interactive.secondaryDisabled}>
                  <BankIcon />
                </CircledIcon>
              }
              id={FiatMethods.WIRE}
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
          <InputRadius topRadiusFlat={true} bottomRadiusFlat={true}>
            <CustomRadio
              testId="radioCard"
              id={FiatMethods.CARD}
              title={t('bank_card_transfer')}
              description={t(depositWithCardDescription)}
              icon={
                <CircledIcon color={colors.interactive.secondaryDisabled}>
                  <CardIcon />
                </CircledIcon>
              }
              // disabled
              // checked={false}
              disabled={bankCardDisabledStatus} // NOTE: enable card deposit
              checked={
                fiatMethod === FiatMethods.CARD && // NOTE: enable card deposit
                !(currencyTogglerValue === CurrencyType.USD)
              }
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
          <InputRadius topRadiusFlat={true}>
            <CustomRadio
              testId="radioCash"
              id={FiatMethods.CASH}
              title={t('bank_cash_transfer')}
              description={t('bank_cash_transfer_fee')}
              icon={
                <CircledIcon color={colors.interactive.secondaryDisabled}>
                  <CardIcon />
                </CircledIcon>
              }
              disabled={
                currencyTogglerValue === CurrencyType.BGEL ||
                !isCashDepositAvailable.isAvailable
              }
              checked={
                fiatMethod === FiatMethods.CASH &&
                !(currencyTogglerValue === CurrencyType.BGEL)
              }
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
        </JurisdictionRenderer>
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
          <InputRadius>
            <CustomRadio
              testId="radioWire"
              title={t('bank_transfer_sepa')}
              description={t('bank_transfer_fee')}
              checked={fiatMethod === FiatMethods.SEPA}
              disabled={!isBankWireDepositAvailable.isAvailable}
              icon={
                <CircledIcon color={colors.interactive.secondaryDisabled}>
                  <BankIcon />
                </CircledIcon>
              }
              id={FiatMethods.SEPA}
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
        </JurisdictionRenderer>
      </CustomInputRadiusContainer>
      <Button
        className="start_deposit"
        testId="fiatBtn"
        disabled={!fiatMethod || !currencyTogglerValue}
        onClick={nextStep}
        title={t('common_continue')}
        isLoading={loading}
      />
    </>
  );
};

export default PickDepositFiatMethod;
