import styled from 'styled-components';

import { OuterLink, TransparentButton } from '@libs/components';

type ContactUsProps = {
  isVisible: boolean;
};

export const ContactUsCustomComponent = styled.div<ContactUsProps>`
  position: absolute;
  bottom: 0;

  height: ${({ isVisible }) => (isVisible ? '27rem' : '0')};
  width: 100%;

  transition: height 0.3s ease;

  padding: ${({ isVisible }) => (isVisible ? '2.5rem 2.4rem 3.4rem' : '0')};

  border-top-left-radius: ${({ theme }) => theme.borderRadius.high};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.high};

  background-color: ${({ theme }) => theme.colors.surface.default};

  box-shadow: 0px -6px 74px 0px rgba(0, 0, 0, 0.11);
  backdrop-filter: blur(2px);
`;

export const ContactWrapper = styled(TransparentButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 5.8rem;
  width: 100%;

  margin-bottom: 1.4rem;
  padding: 0 1.4rem;

  border-radius: ${({ theme }) => theme.borderRadius.medium};

  background-color: ${({ theme }) =>
    theme.colors.interactive.secondaryDisabled};
`;

export const CustomLink = styled(OuterLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 5.8rem;
  width: 100%;

  margin-bottom: 1.4rem;
  padding: 0 1.4rem;

  border-radius: ${({ theme }) => theme.borderRadius.medium};

  background-color: ${({ theme }) =>
    theme.colors.interactive.secondaryDisabled};
`;

export const ContactIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 3.5rem;
  height: 3.5rem;

  border-radius: ${({ theme }) => theme.borderRadius.highest};

  background-color: ${({ theme }) => theme.colors.interactive.brandColor};
`;

export const ContactTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled(TransparentButton)`
  position: absolute;
  top: -2rem;
  right: 1.4rem;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.2rem;
  height: 3.2rem;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.highest};

  cursor: pointer;

  background-color: ${({ theme }) => theme.colors.surface.default};
`;
