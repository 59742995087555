import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import {
  Button,
  SelectData,
  SelectModal,
  Typography,
  ErrorMessage,
  TransparentButton,
  QrReader,
  TableCell,
  RadioButton,
} from '@libs/components';
import { WithdrawalMethods } from '@libs/types';
import {
  FeatureModuleEnum,
  JurisdictionsEnum,
  NetworkAddressRegex,
} from '@libs/backend-common';

import {
  useModal,
  useApiResponse,
  useTranslation,
  useWithdrawalFees,
  CurrencyOptionWithBalance,
  useJurisdictionDetector,
} from 'src/hooks';
import { ScanIcon as QrScanIcon } from 'src/assets';
import { getWithdrawalAddressesQuery } from 'src/gql';
import {
  CurrencyInput,
  FeatureRenderer,
  SaveOrChooseAddress,
  SelectModalHeaderRenderOption,
} from 'src/components';
import { parseWalletAddress } from 'src/helpers';

import { useNetworkSelect } from '../deposit-address/hooks/useNetworkSelect';
import {
  AmountInputContainer,
  CustomSelectModal,
  TransactionStatusContainer,
  TransactionStatusItem,
  WithdrawCryptoContainer,
  ValueContainer,
  ValueTypography,
  ValueWrapper,
  ErrMessageWrapper,
  CustomInput,
  OptionContainer,
  OptionContainerRightCorner,
} from './styles';

type WithdrawCryptoFormProps = {
  nextStep: () => void;
  // setReceiveAmount: (val?: number) => void;
  setAmount: (val: string) => void;
  setAddress: (val: string) => void;
  setSelectedAddressId: (val: string | number) => void;
  setSelectedNetwork: (val: string | number) => void;
  setSelectedNetworkName: (val: string) => void;
  receiveAmount: number | undefined;
  selectedNetwork?: string | number;
  fee: number | undefined;
  sel: string | number;
  limitErrorMessage?: string;
  selectedAddressId: string | number;
  address: string;
  amount: string;
  setAddressName: (val: string) => void;
  options: CurrencyOptionWithBalance[];
  setIsAddressValid: (val: boolean) => void;
  setBnbMemo: (val: string) => void;
  bnbMemo: string;
  isMemoRequired?: boolean;
};
export const WithdrawCryptoForm: React.FC<WithdrawCryptoFormProps> = ({
  nextStep,
  receiveAmount,
  setAmount,
  amount,
  setAddress,
  address,
  fee,
  setAddressName,
  selectedNetwork,
  setSelectedNetwork,
  setSelectedNetworkName,
  setSelectedAddressId,
  selectedAddressId,
  limitErrorMessage,
  sel,

  options,
  setIsAddressValid,
  setBnbMemo,
  bnbMemo,
  isMemoRequired,
}) => {
  const navigate = useNavigate();
  const { currentJurisdiction } = useJurisdictionDetector();
  const shouldCheckKyc = currentJurisdiction === JurisdictionsEnum.GE;

  const { t } = useTranslation();
  const { colors } = useTheme();
  const { getWithdrawalFees } = useWithdrawalFees();
  const { data } = useQuery(getWithdrawalAddressesQuery, {
    fetchPolicy: 'network-only',
  });
  const { response } = useApiResponse({ data });
  const { Dialog, openModal, closeModal } = useModal();

  const [shouldSaveAddress, setShouldSaveAddress] = useState(false);
  const [assetSearchValue, setAssetSerachValue] = useState('');
  const [networkSearchValue, setNetworkSerachValue] = useState('');
  const [addressFormatError, setAddressFormatError] = useState('');
  const [saveAddressClicked, setSaveAddressClicked] = useState(false);
  const savedAddressRef = useRef<string>();

  const selectedAsset = useMemo(
    () => options.find((asset) => asset.value === sel),
    [options, sel]
  );

  const balanceInCurrency = useMemo(() => {
    return selectedAsset?.amount;
  }, [selectedAsset]);

  const { getSupportedNetworks, networksForSelect, networksArray } =
    useNetworkSelect();

  useEffect(() => {
    if (networksForSelect?.length === 1) {
      setSelectedNetworkName(networksForSelect[0].label);
      setSelectedNetwork(networksForSelect[0].value);
    } else if (networksForSelect.length > 1) {
      setSelectedNetwork('');
    }
  }, [networksForSelect, setSelectedNetwork, setSelectedNetworkName]);

  const handleCurrencySelectValue = useCallback(
    (val: string | number) => {
      navigate(`/withdraw?selectedCurrency=${val}`);
      setAddress('');
      setSaveAddressClicked(false);
      setShouldSaveAddress(false);
    },
    [navigate, setAddress, setShouldSaveAddress]
  );

  const errorMessage = useMemo(() => {
    if (Number(amount) > Number(balanceInCurrency)) {
      return 'error_not_enough_balance';
    } else {
      return '';
    }
  }, [amount, balanceInCurrency]);
  const handleCurrencyInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const saveAddressError =
    saveAddressClicked && address.length < 1 ? t('validation_required') : '';

  const inputErrorMessage = t(addressFormatError) || saveAddressError;

  useEffect(() => {
    getWithdrawalFees({
      assetCode: sel,
      method: WithdrawalMethods.FIREBLOCKS,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sel]);

  const validateWalletAddress = (
    selectedNetworkId: string,
    address: string
  ) => {
    const network = networksArray?.find((network) => {
      return network.fireblocksId === selectedNetworkId;
    });

    if (network && network.code) {
      const foundRegex =
        NetworkAddressRegex[network.code as keyof typeof NetworkAddressRegex];
      const regex = new RegExp(foundRegex);
      if (foundRegex && !regex.test(address) && address !== '') {
        setAddressFormatError('error_address_format_invalid');
        setIsAddressValid(false);
      } else {
        setAddressFormatError('');
        setIsAddressValid(true);
      }
    } else {
      setAddressFormatError('');
      setIsAddressValid(true);
    }
  };

  useEffect(() => {
    getSupportedNetworks(sel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sel]);

  useEffect(() => {
    if (selectedNetwork) {
      validateWalletAddress(selectedNetwork.toString(), address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetwork, address]);

  const handleMaxBtn = () => {
    setAmount((balanceInCurrency || 0).toString());
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddress(value);

    const savedAddress = savedAddressRef.current;

    if (saveAddressClicked) {
      setSaveAddressClicked(false);
    }

    if (savedAddress && savedAddress !== value) {
      setShouldSaveAddress(false);
    }
  };

  const onAddressSave = (addressName: string) => {
    setAddressName(addressName);
    savedAddressRef.current = address;
  };

  const mappedRequisites = response?.getCryptoWithdrawalAddresses?.map(
    (req) => {
      return {
        id: req.id,
        title: req.address,
        description: req.name,
      };
    }
  );
  const onAddressSelection = (id: string | number) => {
    const requisites = response?.getCryptoWithdrawalAddresses;
    if (requisites?.length) {
      const foundAddress = requisites.find((i) => i.id === id);
      if (foundAddress) {
        setSelectedAddressId(foundAddress.id);
        setAddress(foundAddress.address);
      }
    }
  };

  const onSuccessScan = (scanResult: string) => {
    if (typeof scanResult !== 'string') return;
    const parsedScanResult = parseWalletAddress(scanResult);

    if (parsedScanResult.amount) {
      setAmount(parsedScanResult.amount.toString());
    }
    setAddress(parsedScanResult.parsedAddress);
    closeModal();
  };

  const handleConfirmation = () => {
    const network = networksArray?.find((network) => {
      return network.fireblocksId === selectedNetwork;
    });
    if (network && network.code) {
      const regex = new RegExp(
        NetworkAddressRegex[network.code as keyof typeof NetworkAddressRegex]
      );
      if (!regex.test(address)) {
        setAddressFormatError('error_address_format_invalid');
        return;
      }
    }
    setAddressFormatError('');
    nextStep();
  };

  const renderOption = useCallback(
    (item: CurrencyOptionWithBalance, index: number) => {
      const isSelected = item.value === sel;
      const handleClick = () => {
        handleCurrencySelectValue(item.value);
      };

      return (
        <OptionContainer
          data-cy={`option-${item?.label}`}
          key={index}
          onClick={handleClick}
        >
          <TableCell
            title={item.name}
            img={item.icon}
            description={String(item.value)}
          />
          <OptionContainerRightCorner>
            <TableCell
              justifyToLeft={false}
              title={`${item.amount} ${item.value}`}
              description={t('common_asset_rate', { amount: item.rate })}
            />
            <RadioButton checked={isSelected} />
          </OptionContainerRightCorner>
        </OptionContainer>
      );
    },
    [handleCurrencySelectValue, sel, t]
  );

  const renderValue = useCallback(
    (val?: SelectData) => (
      <ValueContainer>
        {val?.icon}
        {/* <CustomNetworkImg src="" /> */}
        <ValueWrapper hasIcon={!!val?.icon}>
          <ValueTypography
            type="caption"
            fontFamily="primaryMedium"
            fontSize={val ? 1.2 : 1.6}
            lineHeight={2.4}
            color={colors.text.secondary}
          >
            {t('select_network')}
          </ValueTypography>
          <Typography type="bodyButton" fontFamily="primaryMedium">
            {val?.label}
          </Typography>
        </ValueWrapper>
      </ValueContainer>
    ),
    [colors, t]
  );

  const updateSelectedNetwork = useCallback(
    (network: string | number) => {
      setSelectedNetwork(network);
      const networkObj = networksForSelect?.find((n: SelectData) => {
        return n.value === network;
      });
      setSelectedNetworkName(networkObj ? networkObj.label : '');
    },
    [networksForSelect, setSelectedNetwork, setSelectedNetworkName]
  );
  const assetHeaderRenderOption = (closeSelect: () => void) => {
    return (
      <SelectModalHeaderRenderOption
        selectOptionsLabel={t('filter_select_asset_placeholder')}
        searchInputValue={assetSearchValue}
        setSearchInputValue={setAssetSerachValue}
        closeSelect={closeSelect}
      />
    );
  };

  const networkHeaderRenderOption = (closeSelect: () => void) => {
    return (
      <SelectModalHeaderRenderOption
        selectOptionsLabel={t('select_network')}
        searchInputValue={networkSearchValue}
        setSearchInputValue={setNetworkSerachValue}
        closeSelect={closeSelect}
      />
    );
  };

  const isConfirmationDisabled =
    +amount <= (fee || 0) ||
    !amount ||
    !receiveAmount ||
    receiveAmount < 0 ||
    !address ||
    !!errorMessage ||
    !selectedNetwork;

  const isInvalidAddress =
    !!errorMessage || !!saveAddressError || !!addressFormatError;

  const qrScannerIcon = (
    <TransparentButton onClick={openModal}>
      <QrScanIcon />
    </TransparentButton>
  );
  const saveText = !shouldSaveAddress
    ? t('withdraw_save_address')
    : t('withdraw_save_iban_address_message');

  const maxButtonTitle = balanceInCurrency !== 0 ? t('common_max') : '';

  return (
    <FeatureRenderer
      shouldCheckKyc={shouldCheckKyc}
      acceptedFeature={[FeatureModuleEnum.CRYPTO_WITHDRAWAL]}
    >
      <WithdrawCryptoContainer>
        <Typography
          type="subHeadline"
          fontFamily="primaryBold"
          color={colors.text.default}
        >
          {t('select_amount')}
        </Typography>
        <AmountInputContainer hasError={!!errorMessage}>
          <CurrencyInput
            name="withdrawCryptoForm"
            renderOptionHeader={assetHeaderRenderOption}
            title={t('select_asset')}
            searchInputValue={assetSearchValue}
            inputOnChange={handleCurrencyInputValue}
            selectedValue={sel}
            onChange={handleCurrencySelectValue}
            options={options}
            onBtnClick={handleMaxBtn}
            errorMessage={limitErrorMessage || t(errorMessage)}
            inputPlaceHolder={t('enter_amount')}
            inputValue={amount}
            description={t('common_balance_count', {
              currency: sel,
              amount: String(balanceInCurrency),
            })}
            buttonTitle={maxButtonTitle}
            renderOption={renderOption}
            isUnstable
            roundingPrecision={selectedAsset?.roundingPrecision}
          />
        </AmountInputContainer>
        <SaveOrChooseAddress
          checked={shouldSaveAddress}
          options={mappedRequisites}
          selectedOptionId={selectedAddressId}
          value={address}
          saveText={saveText}
          shouldToggleBeDisabled={isInvalidAddress}
          rightComponent={qrScannerIcon}
          optionsModalTitle={t('withdraw_saved_addresses')}
          // disclaimerText={t('withdraw_modal_disclaimer')}
          inputPlaceholder={t('withdraw_address_placeholder')}
          buttonTitle={t('withdraw_modal_confirm_title_address')}
          saveAddressModalTitle={t('withdraw_modal_confirm_title_address')}
          onOptionSelection={onAddressSelection}
          setChecked={setShouldSaveAddress}
          onButtonClick={onAddressSave}
          onChange={handleAddressChange}
          errorMessage={addressFormatError}
          inputErrorMessage={inputErrorMessage}
          setSaveAddressClicked={setSaveAddressClicked}
        />
        {isMemoRequired && (
          <CustomInput
            value={bnbMemo}
            onChangeText={setBnbMemo}
            placeholder={t('withdraw_insert_memo')}
            rightComponent={qrScannerIcon}
          />
        )}
        <CustomSelectModal>
          <SelectModal
            renderOptionHeader={networkHeaderRenderOption}
            searchInputValue={networkSearchValue}
            options={networksForSelect}
            renderValue={renderValue}
            selectedValue={selectedNetwork}
            onChange={updateSelectedNetwork}
            title={t('select_network')}
          />
        </CustomSelectModal>
        <TransactionStatusContainer>
          <TransactionStatusItem>
            <Typography
              type="caption"
              fontFamily="primaryMedium"
              color={colors.text.secondary}
            >
              {t('withdraw_you_will_receive')}
            </Typography>
            <Typography
              type="bodyButton"
              fontFamily="primaryBold"
              color={colors.text.default}
            >
              {receiveAmount ? receiveAmount + ' ' + sel : '0' + ' ' + sel}
            </Typography>
          </TransactionStatusItem>
          <TransactionStatusItem>
            <Typography
              type="caption"
              fontFamily="primaryMedium"
              color={colors.text.secondary}
            >
              {t('withdraw_fee_included')}
            </Typography>
            <Typography
              type="bodyButton"
              fontFamily="primaryBold"
              color={colors.text.default}
            >
              {fee ? fee + ' ' + sel : '0' + ' ' + sel}
            </Typography>
          </TransactionStatusItem>
        </TransactionStatusContainer>
        <ErrMessageWrapper>
          {!!+amount && +amount <= (fee || 0) && (
            <ErrorMessage
              errorMessage={t('withdraw_crypto_lower_than_fee_error')}
            />
          )}
        </ErrMessageWrapper>
        <Button
          onClick={handleConfirmation}
          disabled={isConfirmationDisabled}
          title={t('common_continue')}
        />
        <Dialog title={t('scan_qr')}>
          <QrReader onSuccessScan={onSuccessScan} />
        </Dialog>
      </WithdrawCryptoContainer>
    </FeatureRenderer>
  );
};
export default WithdrawCryptoForm;
