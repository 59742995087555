import { useTheme } from 'styled-components';
import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const LocationIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      className={className}
      data-cy={testId}
    >
      <path
        d="M8.25417 0C3.69553 0 0 3.69553 0 8.25417C0 10.2893 0.7399 12.1492 1.96093 13.5884L8.25417 21L14.5472 13.5882C15.7682 12.1492 16.5081 10.2891 16.5081 8.25393C16.5083 3.69553 12.8128 0 8.25417 0ZM8.25417 11.3645C6.37933 11.3645 4.85963 9.8448 4.85963 7.96997C4.85963 6.09513 6.37933 4.57543 8.25417 4.57543C10.129 4.57543 11.6487 6.09513 11.6487 7.96997C11.6487 9.8448 10.129 11.3645 8.25417 11.3645Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};

export default LocationIcon;
