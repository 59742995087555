import { useTheme } from 'styled-components';

import { Typography } from '@libs/components';
import { formatBalance } from '@libs/helpers';
import { JurisdictionsEnum } from '@libs/backend-common';

import { useTranslation } from 'src/hooks';
import { BalanceLoader } from 'src/content-loaders';
import { JurisdictionRenderer } from '../jurisdiction-renderer/JurisdictionRenderer';
import DashboardWalletUsdtImages from '../dashboard-balance-usdt/DashboardWalletUsdtImages';

import {
  AllBalance,
  BalanceContainer,
  BalanceDivision,
  Content,
  BalanceWrapper,
  CryptoBalance,
  CryptoBalanceWrapper,
  FiatBalance,
  FiatBalanceWrapper,
  Line,
  CustomCurrencySwitcher,
  TotalBalance,
  UABalanceContainer,
  CustomBalanceWrapper,
  ContentContainer,
  TotalBalanceUa,
} from './styles';

type BalanceProps = {
  totalAmount?: number;
  crypto?: number;
  fiat?: number;
  currencySymbol: string;
  testId?: string;
  loading?: boolean;
};

const UABalance: React.FC<BalanceProps> = ({
  totalAmount,
  crypto,
  fiat,
  currencySymbol,
  loading,
  testId = 'balance',
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  if (loading) {
    return <BalanceLoader />;
  }

  return (
    <>
      <JurisdictionRenderer
        acceptedJurisdictions={[JurisdictionsEnum.GE, JurisdictionsEnum.EU]}
      >
        <BalanceContainer data-cy={testId}>
          <Content>
            <BalanceWrapper>
              <Typography
                type="bodyButton"
                fontFamily="primaryMedium"
                testId="totalBalance"
                color={colors.interactive.brandColor}
              >
                {t('balance_all')}
              </Typography>
              <AllBalance>
                <TotalBalance
                  type="titleOne"
                  testId="totalAmount"
                  fontFamily="primaryBold"
                >
                  {currencySymbol} {formatBalance(totalAmount)}
                </TotalBalance>
              </AllBalance>
            </BalanceWrapper>

            <CustomCurrencySwitcher />
          </Content>

          <BalanceDivision>
            <CryptoBalanceWrapper>
              <Typography
                type="caption"
                testId="cryptoBalance"
                fontFamily="primaryMedium"
                color={colors.interactive.brandColor}
              >
                {t('balance_crypto')}
              </Typography>
              <CryptoBalance>
                <Typography
                  type="subHeadline"
                  testId="cryptoAmount"
                  fontFamily="primaryMedium"
                >
                  {currencySymbol} {formatBalance(crypto)}
                </Typography>
              </CryptoBalance>
            </CryptoBalanceWrapper>
            <Line />
            <FiatBalanceWrapper>
              <Typography
                type="caption"
                testId="fiatBalance"
                fontFamily="primaryMedium"
                color={colors.interactive.brandColor}
              >
                {t('balance_fiat')}
              </Typography>
              <FiatBalance>
                <Typography
                  type="subHeadline"
                  testId="fiatAmount"
                  fontFamily="primaryMedium"
                >
                  {currencySymbol} {formatBalance(fiat)}
                </Typography>
              </FiatBalance>
            </FiatBalanceWrapper>
          </BalanceDivision>
        </BalanceContainer>
      </JurisdictionRenderer>

      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.UA]}>
        <UABalanceContainer>
          <ContentContainer>
            <CustomBalanceWrapper>
              <Typography
                type="bodyButton"
                fontFamily="primaryMedium"
                testId="totalBalance"
                color={colors.interactive.brandColor}
              >
                {t('balance_all')}
              </Typography>
              <AllBalance>
                <TotalBalanceUa
                  type="titleOne"
                  testId="totalAmount"
                  fontFamily="primaryBold"
                >
                  {formatBalance(crypto) + ' USDT'}
                </TotalBalanceUa>
              </AllBalance>
            </CustomBalanceWrapper>
            <DashboardWalletUsdtImages />
          </ContentContainer>
        </UABalanceContainer>
      </JurisdictionRenderer>
    </>
  );
};

export default UABalance;
