import { useQuery } from '@apollo/client';
import { FrStatuses, meQuery } from '@libs/graphql';
import { UserDetails as NativeUserDetails } from '@libs/components';

import { useApiResponse, useTranslation } from 'src/hooks';
import { UserDetailsLoader } from 'src/content-loaders';

type UserDetailsProps = {
  showLastName?: boolean;
  showGreeting?: boolean;
  onBtnClick?: () => void;
  hasButton?: boolean;
  imgSize?: number;
  className?: string;
};

const UserDetails: React.FC<UserDetailsProps> = ({
  showLastName,
  showGreeting,
  onBtnClick,
  hasButton,
  imgSize,
  className,
}) => {
  const { t } = useTranslation();

  const { data, loading: DetailsLoading } = useQuery(meQuery);
  const { response, loading } = useApiResponse({
    data,
    loading: DetailsLoading,
  });

  if (!response || loading) {
    return <UserDetailsLoader showGreeting={showGreeting} size={imgSize} />;
  }

  const { officialFirstName, officialLastName, name, image, frStatus } =
    response.me;

  const lastName =
    showLastName && officialLastName
      ? officialLastName[0].toUpperCase() + '.'
      : '';

  const greetingText = t('common_greeting', { name: '' });
  const isVerified = frStatus === FrStatuses.VERIFIED;

  return (
    <NativeUserDetails
      officialFirstName={officialFirstName}
      officialLastName={lastName}
      id={name}
      className={className}
      image={image}
      imgSize={imgSize}
      idTitle="#"
      showGreeting={showGreeting}
      greetingText={greetingText}
      isVerified={isVerified}
      onBtnClick={onBtnClick}
      buttonTitle={t('common_edit_avatar')}
      hasButton={hasButton}
    />
  );
};
export default UserDetails;
