import { PrivateRoute as NativePrivateRoute } from '@libs/components';
import { useAuth } from '@libs/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TOKEN_KEY } from 'src/constants';
import { useLanguageAndThemeFromURL } from 'src/hooks';

type PrivateRouteProps = {
  component: React.ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const [isUserValidated, setIsUserValidated] = useState(false);

  const navigate = useNavigate();

  useLanguageAndThemeFromURL();

  const onLogout = useCallback(() => {
    const queryString = window.location.search;

    const additionalRouteQuery = queryString || '';

    navigate(`/login${additionalRouteQuery}`, {
      replace: true,
    });
  }, [navigate]);

  const { logout, checkTokenValidity, decodeToken } = useAuth(onLogout);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);

    const decodedToken = decodeToken();

    const shouldValidateTwoFa =
      decodedToken?.isEmailTwoFactorEnabled ||
      decodedToken?.isSmsTwoFactorEnabled ||
      decodedToken?.isTwoFactorEnabled;

    if (token) {
      const isTokenValid = checkTokenValidity(
        token,
        undefined,
        shouldValidateTwoFa
      );

      if (!isTokenValid) {
        logout();
        return;
      }

      setIsUserValidated(true);
    } else {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isUserValidated) {
    return null;
  }

  return <NativePrivateRoute {...props} />;
};

export default PrivateRoute;
