import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const PhoneIcon: React.FC<SvgIconDefaultProps> = ({ className, testId }) => {
  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.20866 12.4587L7.66699 14.0003C7.34199 14.3253 6.82533 14.3253 6.49199 14.0087C6.40033 13.917 6.30866 13.8337 6.21699 13.742C5.35866 12.8753 4.58366 11.967 3.89199 11.017C3.20866 10.067 2.65866 9.11699 2.25866 8.17533C1.86699 7.22533 1.66699 6.31699 1.66699 5.45033C1.66699 4.88366 1.76699 4.34199 1.96699 3.84199C2.16699 3.33366 2.48366 2.86699 2.92533 2.45033C3.45866 1.92533 4.04199 1.66699 4.65866 1.66699C4.89199 1.66699 5.12533 1.71699 5.33366 1.81699C5.55033 1.91699 5.74199 2.06699 5.89199 2.28366L7.82533 5.00866C7.97533 5.21699 8.08366 5.40866 8.15866 5.59199C8.23366 5.76699 8.27533 5.94199 8.27533 6.10033C8.27533 6.30033 8.21699 6.50033 8.10033 6.69199C7.99199 6.88366 7.83366 7.08366 7.63366 7.28366L7.00033 7.94199C6.90866 8.03366 6.86699 8.14199 6.86699 8.27533C6.86699 8.34199 6.87533 8.40033 6.89199 8.46699C6.91699 8.53366 6.94199 8.58366 6.95866 8.63366C7.10866 8.90866 7.36699 9.26699 7.73366 9.70033C8.10866 10.1337 8.50866 10.5753 8.94199 11.017C9.02532 11.1003 9.11699 11.1837 9.20033 11.267C9.53366 11.592 9.54199 12.1253 9.20866 12.4587Z"
        fill="white"
      />
      <path
        d="M18.3083 15.2752C18.3083 15.5085 18.2667 15.7502 18.1833 15.9835C18.1583 16.0502 18.1333 16.1169 18.1 16.1835C17.9583 16.4835 17.775 16.7669 17.5333 17.0335C17.125 17.4835 16.675 17.8085 16.1667 18.0169C16.1583 18.0169 16.15 18.0252 16.1417 18.0252C15.65 18.2252 15.1167 18.3335 14.5417 18.3335C13.6917 18.3335 12.7833 18.1335 11.825 17.7252C10.8667 17.3169 9.90833 16.7669 8.95833 16.0752C8.63333 15.8335 8.30833 15.5919 8 15.3335L10.725 12.6085C10.9583 12.7835 11.1667 12.9169 11.3417 13.0085C11.3833 13.0252 11.4333 13.0502 11.4917 13.0752C11.5583 13.1002 11.625 13.1085 11.7 13.1085C11.8417 13.1085 11.95 13.0585 12.0417 12.9669L12.675 12.3419C12.8833 12.1335 13.0833 11.9752 13.275 11.8752C13.4667 11.7585 13.6583 11.7002 13.8667 11.7002C14.025 11.7002 14.1917 11.7335 14.375 11.8085C14.5583 11.8835 14.75 11.9919 14.9583 12.1335L17.7167 14.0919C17.9333 14.2419 18.0833 14.4169 18.175 14.6252C18.2583 14.8335 18.3083 15.0419 18.3083 15.2752Z"
        fill="white"
      />
    </CustomSvg>
  );
};

export default PhoneIcon;
