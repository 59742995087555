import { useState } from 'react';

import { DefaultTabItem } from '@libs/components';
import { WalletTypeEnum } from '@libs/backend-common';

import { Balance, PageContainer } from 'src/components';

import { useTranslation } from 'src/hooks';
import { CustomAllAssetsTable } from './styles';

const UserWallet = () => {
  const { t } = useTranslation();
  const tabs: DefaultTabItem[] = [
    {
      id: 0,
      title: t('common_crypto'),
    },
    {
      id: 1,
      title: t('common_fiat'),
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <PageContainer>
      <Balance />
      <CustomAllAssetsTable
        shouldHaveExtraColumns={false}
        walletType={WalletTypeEnum.ALL_ASSETS}
        title={t('dashboard_wallet_title')}
        isCrypto={activeTab === 0}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        hasActions={true}
      />
    </PageContainer>
  );
};
export default UserWallet;
