import { useTheme } from 'styled-components';

import { Typography } from '@libs/components';

import { BalanceType, CurrencyType } from 'src/constants';
import { useJurisdictionDetector, useTranslation } from 'src/hooks';

import { JurisdictionsEnum } from '@libs/backend-common';
import { JurisdictionRenderer } from '../jurisdiction-renderer/JurisdictionRenderer';
import {
  CurrencyContainer,
  CurrencyWrapper,
  CustomEurIcon,
  CustomGelIcon,
  CustomUsdIcon,
} from './styles';

type CurrencyTogglerProps = {
  activeCurrency?: CurrencyType;
  testId?: string;
  onChange?: (id: CurrencyType) => void;
};

const CurrencyToggler: React.FC<CurrencyTogglerProps> = ({
  activeCurrency,
  testId = 'currency-toggler',
  onChange,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { currentJurisdiction } = useJurisdictionDetector();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const elementId = e.currentTarget.id as CurrencyType;
    if (onChange) onChange(elementId);
  };

  const bgelOrEurTabId =
    currentJurisdiction === JurisdictionsEnum.GE
      ? BalanceType.BGEL
      : BalanceType.EUR;

  const busdOrUsdTabId =
    currentJurisdiction === JurisdictionsEnum.GE
      ? BalanceType.BUSD
      : BalanceType.USD;

  const isGelOrEurActive =
    activeCurrency &&
    [CurrencyType.GEL, CurrencyType.BGEL, CurrencyType.EUR].includes(
      activeCurrency
    );
  const isUSDActive =
    activeCurrency &&
    [CurrencyType.USD, CurrencyType.BUSD].includes(activeCurrency);

  return (
    <CurrencyContainer data-testid={testId} data-cy={testId}>
      <CurrencyWrapper
        data-cy="gel"
        data-testid="gel"
        isActive={!!isGelOrEurActive}
        activeCurrency={activeCurrency}
        onClick={handleClick}
        id={bgelOrEurTabId}
      >
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
          <CustomGelIcon testId="gel-icon" isActive={!!isGelOrEurActive} />
        </JurisdictionRenderer>
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
          <CustomEurIcon
            testId="eur-icon"
            isActive={activeCurrency === CurrencyType.EUR}
          />
        </JurisdictionRenderer>
        <Typography
          type="bodyButton"
          testId="gel-text"
          fontFamily="primaryMedium"
        >
          <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
            {t('currency_gel')}
          </JurisdictionRenderer>
          <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
            {t('currency_eur')}
          </JurisdictionRenderer>
        </Typography>
      </CurrencyWrapper>
      <CurrencyWrapper
        data-cy="usd"
        data-testid="usd"
        isActive={!!isUSDActive}
        onClick={handleClick}
        activeCurrency={activeCurrency}
        id={busdOrUsdTabId}
      >
        <CustomUsdIcon
          isActive={!!isUSDActive}
          testId="usd-icon"
          color={isUSDActive ? colors.surface.default : undefined}
        />
        <Typography
          type="bodyButton"
          testId="usd-text"
          fontFamily="primaryMedium"
        >
          {t('currency_usd')}
        </Typography>
      </CurrencyWrapper>
    </CurrencyContainer>
  );
};

export default CurrencyToggler;
