import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';

import { useTheme } from 'styled-components';

import { useNavigate } from 'react-router-dom';

import Lottie from 'lottie-react';

import {
  resultFeedbackSuccessAnimation,
  Typography,
  Button,
} from '@libs/components';

import { useApiResponse, useDimensions } from '@libs/hooks';
import { TwoFaActions, TwoFactorAuthType } from '@libs/types';
import { screenSizes } from '@libs/theme';
import { meQuery } from '@libs/graphql';

import { useTranslation, useModal } from 'src/hooks';

import ActivateTwoFa from '../activate-two-fa/ActivateTwoFa';

import { TwoFaSteps } from '..';

import {
  ResultWrapper,
  Container,
  LootieWrapper,
  ButtonsWrapper,
} from './styles';

type RegisterSuccessProps = {
  openDialog: () => void;
  isNewlyRegistered: string | null;
};

export const RegisterSuccess: React.FC<RegisterSuccessProps> = ({
  isNewlyRegistered,
  openDialog,
}) => {
  const navigate = useNavigate();

  const [type, setType] = useState<TwoFactorAuthType>();

  const [action, setAction] = useState<TwoFaActions>(TwoFaActions.ACTIVATE);

  const handleClick = useCallback(
    (type: TwoFactorAuthType, isChecked: boolean) => {
      if (isChecked) {
        setAction(TwoFaActions.DEACTIVATE);
      } else {
        setAction(TwoFaActions.ACTIVATE);
      }
      setType(type);
    },
    []
  );

  const clearType = useCallback(() => setType(undefined), []);

  const { t } = useTranslation();

  const { colors } = useTheme();

  const { width } = useDimensions();

  const { data } = useQuery(meQuery, { fetchPolicy: 'network-only' });
  const { response } = useApiResponse({ data });

  const user = response?.me;

  const { Dialog: SuccessModal, closeModal: closeSuccessModal } = useModal(
    undefined,
    !!isNewlyRegistered
  );

  const {
    Dialog: TwoFaDialog,
    openModal: openTwoFaModal,
    closeModal: closeTwoFaModal,
  } = useModal();

  if (!isNewlyRegistered) return null;

  const handleGoToDashboard = () => {
    closeSuccessModal();
    if (width < screenSizes.laptop) {
      openDialog();
    }
  };

  const handleTwoFaActivationClick = () => {
    if (width < screenSizes.laptop) {
      navigate('/activate-2FA');
    } else {
      closeSuccessModal();
      openTwoFaModal();
    }
  };

  const authMethod = user?.isSmsTwoFactorEnabled ? 'SMS' : 'Email';

  return (
    <>
      <TwoFaDialog leftSpace={0}>
        {type ? (
          <TwoFaSteps
            onFinish={clearType}
            typeToActivate={type}
            action={action}
            closeModal={closeTwoFaModal}
          />
        ) : (
          <ActivateTwoFa
            handleClose={closeTwoFaModal}
            handleTogglerClick={handleClick}
          />
        )}
      </TwoFaDialog>
      <SuccessModal title={t('registration_finish')}>
        <Container>
          <ResultWrapper>
            <LootieWrapper>
              <Lottie
                loop={false}
                animationData={resultFeedbackSuccessAnimation}
              />
            </LootieWrapper>
            <Typography type="headline" fontFamily="primaryBold">
              {t('registration_success_title')}
            </Typography>
            <Typography type="bodyButton" color={colors.text.secondary}>
              {t('registration_complete_twofa', { authMethod })}
            </Typography>
          </ResultWrapper>
          <ButtonsWrapper>
            <Button
              title={t('registration_active_twofa')}
              onClick={handleTwoFaActivationClick}
              buttonType="secondary"
            />
            <Button
              title={t('registration_go_to_dashboard')}
              onClick={handleGoToDashboard}
            />
          </ButtonsWrapper>
        </Container>
      </SuccessModal>
    </>
  );
};

export default RegisterSuccess;
