import { useTheme } from 'styled-components';
import {
  useCurrencyIcons,
  useNavigationItems,
  useTranslation,
} from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { TransparentButton } from '@libs/components';
import { useModal } from '@libs/components';
import { getAlternativeAssetLabel } from 'src/helpers';

import { ActionIcon } from 'src/assets';
import { CurrencyType } from 'src/constants';
import { default as MobileNavItem } from '../dashboard-mobile-nav/components/ModalItem';
import {
  Wrapper,
  ActionBtn,
  Separator,
  ActionIconWrapper,
  ModalWrapper,
} from './styles';

type WalletActionsProps = {
  currency: CurrencyType;
  isCrypto: boolean;
};

const WalletActions: React.FC<WalletActionsProps> = ({
  currency,
  isCrypto,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const additionalRouteQuery = `?selectedCurrency=${currency}`;

  const navItems = useNavigationItems({
    color: colors.interactive.brandColor,
    additionalRouteQuery,
  });
  const ICONS = useCurrencyIcons('3.2rem');

  const { Dialog, openModal, closeModal } = useModal();

  const handleNavigation = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const path = e.currentTarget.getAttribute('data-path');
    if (path) {
      navigate(`/${path}${additionalRouteQuery}`);
    }
  };

  const selectedCurrency = getAlternativeAssetLabel(currency);

  return (
    <>
      <Wrapper>
        <ActionBtn
          buttonType="secondary"
          size="sm"
          data-path={isCrypto ? 'convert/SELL' : 'convert/BUY'}
          onClick={handleNavigation}
          title={t('wallet_action_convert')}
        />
        <Separator>
          <ActionBtn
            buttonType="secondary"
            size="sm"
            data-path="deposit"
            onClick={handleNavigation}
            title={t('wallet_action_deposit')}
          />
        </Separator>
        <ActionBtn
          buttonType="secondary"
          size="sm"
          data-path="withdraw"
          onClick={handleNavigation}
          title={t('wallet_action_withdraw')}
        />
      </Wrapper>

      <TransparentButton onClick={openModal}>
        <ActionIconWrapper>
          <ActionIcon />
        </ActionIconWrapper>
      </TransparentButton>

      <Dialog
        titleIcon={ICONS[currency]}
        title={t('wallet_actions_for', { currency: selectedCurrency })}
      >
        <ModalWrapper>
          {navItems.map((item) => (
            <MobileNavItem
              item={item}
              key={item.route}
              closeModal={closeModal}
            />
          ))}
        </ModalWrapper>
      </Dialog>
    </>
  );
};
export default WalletActions;
