import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { NegativeIcon, PositiveIcon, Typography } from '@libs/components';
import { JurisdictionsEnum } from '@libs/backend-common';
import { formatBalance } from '@libs/helpers';
import { screenSizes } from '@libs/theme';
import { useApiResponse, useDimensions } from '@libs/hooks';
import { FrStatuses } from '@libs/graphql';

import {
  getBalanceHistoryByUserQuery,
  getTotalBalanceQuery,
  getUserTransactionsCountQuery,
} from 'src/gql';
import { roundDown } from 'src/helpers';
import {
  PRICES_POLL_INTERVAL,
  TransactionFiltersDefaultValue,
} from 'src/constants';
import {
  BalanaceFinancialChart,
  BalanceKyc,
  JurisdictionRenderer,
  RequireDeposit,
} from 'src/components';
import { UserBalanceLoader, UserBalanceChartLoader } from 'src/content-loaders';
import {
  useCurrency,
  useFetchPriceQueries,
  useNavigationItems,
  useTranslation,
} from 'src/hooks';
import { BalanceTogglerIcon } from 'src/assets';

import { useBalanceTabsOptions } from './hooks';

import {
  ContentWrapper,
  AllBalance,
  BalanceContainer,
  BalanceWrapper,
  Content,
  CustomCurrencySwitcher,
  CryptoBalanceWrapper,
  BalanceDivision,
  CryptoBalance,
  FiatBalanceWrapper,
  FiatBalance,
  Line,
  AllBalanceTypography,
  IconWrapper,
  TextWrapper,
  ChartContainer,
  FinancialWrapper,
  ContentContainer,
  CustomTabs,
  ChartWrapper,
  NavigationItemIconWrapper,
  NavigationItemsContainer,
  ItemWrapper,
  CurrencySwitcherWrapper,
  TabsWrapper,
  ChangesWrapper,
} from './styles';

const Balance = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { currencySymbol, currency } = useCurrency();
  const [isDroppedDown, setIsDoppedDown] = useState(false);
  const { colors } = useTheme();
  const { width } = useDimensions();

  const {
    activeTabObject,
    meResponseLoading,
    setActiveTab,
    tabItems,
    activeTab,
    meResponse,
    shouldDisplayTabs,
  } = useBalanceTabsOptions();

  const { fetchPriceQueriesStatus } = useFetchPriceQueries();

  const [fetchTotalBalances, balanceQuery] = useLazyQuery(
    getTotalBalanceQuery,
    {
      variables: {
        record: {
          assetCode: currency,
          historyInterval: activeTabObject.amountOfDays,
        },
      },
      fetchPolicy: 'network-only',
      pollInterval: PRICES_POLL_INTERVAL,
    }
  );

  const [fetchBalanceHistory, balanceHistoryQuery] = useLazyQuery(
    getBalanceHistoryByUserQuery,
    {
      variables: {
        record: {
          duration: activeTabObject.dayEnum,
          valueAssetCode: currency,
        },
      },
      fetchPolicy: 'network-only',
      pollInterval: PRICES_POLL_INTERVAL,
    }
  );

  useEffect(() => {
    fetchTotalBalances();
    fetchBalanceHistory();
  }, [fetchTotalBalances, fetchBalanceHistory, fetchPriceQueriesStatus]);

  const transactionRes = useQuery(getUserTransactionsCountQuery, {
    variables: {
      record: {
        assetCodes: TransactionFiltersDefaultValue.ASSET,
        types: TransactionFiltersDefaultValue.TYPE,
        statuses: TransactionFiltersDefaultValue.STATUS,
        minDate: TransactionFiltersDefaultValue.MINDATE,
        maxDate: TransactionFiltersDefaultValue.MAXDATE,
      },
    },
  });

  const { response: transactionsRes, loading: transactionsResLoading } =
    useApiResponse(transactionRes);

  const { response: balanceHistoryRes, loading: balanceHistoryResLoading } =
    useApiResponse(balanceHistoryQuery);
  const { response, loading: balanceLoading } = useApiResponse(balanceQuery);

  const handleIconClick = () => setIsDoppedDown((prev) => !prev);

  const fixedCrypto = roundDown(response?.getTotalBalanceForUser.crypto || 0);
  const fixedFiat = roundDown(response?.getTotalBalanceForUser.fiat || 0);
  const totalAmount = roundDown(fixedCrypto + fixedFiat);

  const formatedTotalAmount = formatBalance(totalAmount);
  const formatedFixedCrypto = formatBalance(fixedCrypto);
  const formatedFixedFiat = formatBalance(fixedFiat);

  const navItems = useNavigationItems({ color: colors.interactive.brandColor });

  const totalPercentageChange =
    response?.getTotalBalanceForUser?.totalPercentageChange || 0;

  const hasBalanceGrown = response && totalPercentageChange > 0;

  const chartColor = hasBalanceGrown
    ? colors.text.success
    : colors.text.critical;

  const PositiveOrNegativeSign = hasBalanceGrown ? (
    <PositiveIcon />
  ) : (
    <NegativeIcon />
  );
  const res = response?.getTotalBalanceForUser;

  const totalAmountChanges = useMemo(() => {
    if (!res?.totalValueChange) return '';
    else {
      const valueChangeWithoutMinusSign = Math.abs(res.totalValueChange);

      return `${currencySymbol}${valueChangeWithoutMinusSign} `;
    }
  }, [currencySymbol, res]);

  const totalAmountChangesInPercentage = useMemo(() => {
    if (!res?.totalPercentageChange) return '';
    else {
      const percentChange = Math.abs(res?.totalPercentageChange);
      return `${percentChange}%`;
    }
  }, [res?.totalPercentageChange]);

  let renderItem: React.ReactNode = null;

  const isResponseLoading =
    transactionsResLoading || meResponseLoading || balanceHistoryResLoading;

  if (!isResponseLoading) {
    if (meResponse?.me.frStatus !== FrStatuses.VERIFIED) {
      renderItem = <BalanceKyc />;
    } else if (transactionsRes?.getUserTransactionsCount === 0) {
      renderItem = <RequireDeposit />;
    } else {
      const shouldHideAxisLines = width < screenSizes.laptop;
      renderItem = (
        <>
          <ChartWrapper>
            <BalanaceFinancialChart
              tooltipPrecision={2}
              shouldDetectColor={true}
              data={balanceHistoryRes?.getBalanceHistoryByUser}
              currencySymbol={currencySymbol}
              dateFormat={activeTabObject.dateFormat}
              shouldHideXAxis={shouldHideAxisLines}
              shouldHideYAxis={shouldHideAxisLines}
              tickInterval={7}
            />
          </ChartWrapper>
          {shouldDisplayTabs && (
            <TabsWrapper shouldStrecth={tabItems.length > 2}>
              <CustomTabs
                activeTab={activeTab}
                tabs={tabItems}
                handleTabClick={setActiveTab}
              />
            </TabsWrapper>
          )}
        </>
      );
    }
  }

  return (
    <ContentWrapper>
      <BalanceContainer>
        {balanceLoading ? (
          <UserBalanceLoader isOpened={isDroppedDown} />
        ) : (
          <ContentContainer>
            <FinancialWrapper>
              <Content>
                <CurrencySwitcherWrapper>
                  <JurisdictionRenderer
                    acceptedJurisdictions={[JurisdictionsEnum.GE]}
                  >
                    <CustomCurrencySwitcher />
                  </JurisdictionRenderer>
                </CurrencySwitcherWrapper>
                <BalanceWrapper>
                  <Typography
                    type="bodyButton"
                    fontFamily="primaryBold"
                    color={colors.interactive.brandColor}
                  >
                    {t('balance_all')}
                  </Typography>

                  <AllBalance>
                    <AllBalanceTypography
                      type="largeTitle"
                      fontFamily="primaryBold"
                    >
                      {currencySymbol}
                      {formatedTotalAmount}
                    </AllBalanceTypography>

                    <IconWrapper
                      onClick={handleIconClick}
                      isDroppedDown={isDroppedDown}
                    >
                      <BalanceTogglerIcon />
                    </IconWrapper>
                  </AllBalance>
                  {!!response?.getTotalBalanceForUser.totalValueChange && (
                    <TextWrapper>
                      <Typography
                        color={colors.text.secondary}
                        type={'bodyButton'}
                        fontFamily="primaryBold"
                      >
                        {activeTabObject.title}
                      </Typography>
                      <ChangesWrapper>
                        {PositiveOrNegativeSign}
                        <Typography
                          color={chartColor}
                          type={'bodyButton'}
                          fontFamily="primaryBold"
                        >
                          {totalAmountChanges}
                        </Typography>
                      </ChangesWrapper>
                      <ChangesWrapper>
                        {PositiveOrNegativeSign}
                        <Typography
                          color={chartColor}
                          type={'bodyButton'}
                          fontFamily="primaryBold"
                        >
                          {totalAmountChangesInPercentage}
                        </Typography>
                      </ChangesWrapper>
                    </TextWrapper>
                  )}
                </BalanceWrapper>
              </Content>
              <BalanceDivision isDroppedDown={isDroppedDown}>
                <CryptoBalanceWrapper>
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={colors.text.secondary}
                  >
                    {t('balance_crypto')}
                  </Typography>
                  <CryptoBalance>
                    <Typography type="subHeadline" fontFamily="primaryMedium">
                      {currencySymbol} {formatedFixedCrypto}
                    </Typography>
                  </CryptoBalance>
                </CryptoBalanceWrapper>
                <Line />
                <FiatBalanceWrapper>
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={colors.text.secondary}
                  >
                    {t('balance_fiat')}
                  </Typography>
                  <FiatBalance>
                    <Typography type="subHeadline" fontFamily="primaryMedium">
                      {currencySymbol} {formatedFixedFiat}
                    </Typography>
                  </FiatBalance>
                </FiatBalanceWrapper>
              </BalanceDivision>
            </FinancialWrapper>
          </ContentContainer>
        )}

        {isResponseLoading ? (
          <UserBalanceChartLoader />
        ) : (
          <ChartContainer>{renderItem}</ChartContainer>
        )}
      </BalanceContainer>
      <NavigationItemsContainer>
        {navItems.map((item, index) => {
          const handleClick = () => {
            navigate(item.route);
          };
          return (
            <ItemWrapper key={index} onClick={handleClick}>
              <NavigationItemIconWrapper>{item.icon}</NavigationItemIconWrapper>
              <Typography
                type="bodyButton"
                fontFamily="primaryBold"
                color={colors.text.default}
              >
                {t(item.title)}
              </Typography>
            </ItemWrapper>
          );
        })}
      </NavigationItemsContainer>
    </ContentWrapper>
  );
};

export default Balance;
