import styled from 'styled-components';

import { TransparentButton } from '@libs/components';
import { DISCLAIMER_HEIGHT } from 'src/constants';

import DashboardNavItem from './component/DashboardNavItem';
import { DASHBOARD_NAV_WIDTH } from './DashboardNav';

type ShadowProps = {
  shadow?: string;
};

export const CustomDashboardNavItem = styled(DashboardNavItem)`
  border-radius: ${({ theme }) => theme.borderRadius.high};
`;

type NavProps = {
  isUnderConstruction?: boolean;
};

export const Nav = styled.nav<NavProps>`
  display: none;

  z-index: ${({ theme }) => theme.zIndex.high};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme, isUnderConstruction }) => {
    const borderColor = theme.colors.borders
      .default as unknown as TemplateStringsArray;
    const dashboardWidth =
      DASHBOARD_NAV_WIDTH.toString() as unknown as TemplateStringsArray;
    const top = (isUnderConstruction
      ? `${DISCLAIMER_HEIGHT}rem`
      : '0') as unknown as TemplateStringsArray;

    const minHeight = (isUnderConstruction
      ? '-webkit-fill-available'
      : '100%') as unknown as TemplateStringsArray;
    return theme.media.laptop`
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      position: fixed;
      top: ${top};
      left: 0;

      max-height: 100%;
      min-height: ${minHeight};
      width: 100%;
      max-width: ${dashboardWidth}rem;
      min-width: ${dashboardWidth}rem;

      padding: 2.7rem 0 0;

      border-right: 1px solid ${borderColor};
      
      transform: unset;
    `;
  }}
`;

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  flex: 1;
  overflow-y: auto;
`;

export const HomePage = styled(TransparentButton)`
  display: none;

  ${({ theme }) => theme.media.laptop`
    display:flex;

    margin-left:4.1rem;
  `};
`;

export const BitnetLogo = styled.img`
  height: 3.3rem;
`;

export const UserDetailsWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.media.laptop`
    display: flex;

    margin: 6.3rem 0 5.3rem 3.7rem ;
  `}
`;

export const PaddingWrapper = styled.div`
  padding: 0 3.2rem 0 2.9rem;
  min-width: unset;

  ${({ theme }) => theme.media.laptop`
    display:flex;
    flex-direction:column;
    align-items:center;
    max-width: 23.6rem;
    padding: 0;
    margin: 0 auto;
    min-width: 21.6rem;
`}
`;

export const LanguageWrapper = styled.div`
  height: 8rem;

  display: flex;
  margin-top: 1.6rem;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};
`;

export const Shadow = styled.div<ShadowProps>`
  width: 100%;
  height: 5.5rem;
  background: ${({ shadow }) => shadow};
  @media only screen and (min-height: 900px) {
    display: none;
  }
`;

export const SupportNav = styled.div`
  padding: 0 3.2rem 0 2.9rem;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.interactive.secondary};
  margin-bottom: 4rem;
`;
