import { useNavigate } from 'react-router-dom';

import { bitnetLogo, CrexoneLogo, CrexoneLogoBlack } from 'src/assets';
import { useJurisdictionDetector, useRenderObjectByTheme } from 'src/hooks';

import { JurisdictionsEnum } from '@libs/backend-common';
import UserDetails from '../user-details/UserDetails';
import ActiveSwapsNav from '../active-swaps-navigation/ActiveSwapsNavigation';

import { JurisdictionRenderer } from '../jurisdiction-renderer/JurisdictionRenderer';
import { useDashboardNavItems } from './hooks/useDashboardNavItems';
import {
  Nav,
  NavWrapper,
  PaddingWrapper,
  UserDetailsWrapper,
  HomePage,
  BitnetLogo,
  Shadow,
  SupportNav,
  Line,
  CustomDashboardNavItem,
} from './styles';
import DashboardNavItem from './component/DashboardNavItem';

export const DASHBOARD_NAV_WIDTH = 27.7;

export const DEFAULT_NAV_WITDTH_WITH_PADDING = DASHBOARD_NAV_WIDTH;

const DashboardNav = ({
  testId = 'dashboardNav',
  isUnderConstruction,
}: {
  testId?: string;
  isUnderConstruction?: boolean;
}) => {
  const navigate = useNavigate();
  const { navigationItems, supportNavigationItems } = useDashboardNavItems();
  const crexonelogo = useRenderObjectByTheme(CrexoneLogoBlack, CrexoneLogo);

  const handleNavigate = () => {
    navigate('/');
  };

  const { currentJurisdiction } = useJurisdictionDetector();

  const navShwadowBackground = useRenderObjectByTheme(
    'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FBFBFB 100%)',
    'linear-gradient(180deg, rgba(34, 37, 48, 0) 0%, #15161d 100%)'
  );

  const isGlobalJurisdiction = currentJurisdiction === JurisdictionsEnum.UA;

  return (
    <Nav data-cy={testId} isUnderConstruction={isUnderConstruction}>
      <HomePage data-cy="bitnetLogo" onClick={handleNavigate}>
        <BitnetLogo src={isGlobalJurisdiction ? crexonelogo : bitnetLogo} />
      </HomePage>
      <UserDetailsWrapper>
        <UserDetails showGreeting />
      </UserDetailsWrapper>
      <NavWrapper>
        <PaddingWrapper>
          {navigationItems.map((item) => (
            <DashboardNavItem item={item} key={item.title} />
          ))}
        </PaddingWrapper>
      </NavWrapper>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.UA]}>
        <Shadow shadow={navShwadowBackground} />
        <ActiveSwapsNav />
      </JurisdictionRenderer>
      <JurisdictionRenderer
        acceptedJurisdictions={[JurisdictionsEnum.GE, JurisdictionsEnum.EU]}
      >
        <Line />
        <SupportNav>
          {supportNavigationItems.map((item) => (
            <CustomDashboardNavItem
              shouldRenderArrowIcon={true}
              item={item}
              key={item.title}
            />
          ))}
        </SupportNav>
      </JurisdictionRenderer>
    </Nav>
  );
};

export default DashboardNav;
