import styled from 'styled-components';

import {
  Button,
  SocialOuterLink,
  TransparentButton,
  Typography,
} from '@libs/components';

import { DISCLAIMER_HEIGHT } from 'src/constants';
import CircledIcon from '../circled-icon/CircledIcon';
import { DASHBOARD_NAV_WIDTH } from '../dashboard-nav/DashboardNav';

type ContainerProps = {
  isUnderConstruction?: boolean;
};

export const Container = styled.header<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;

  top: ${({ isUnderConstruction }) =>
    isUnderConstruction ? `${DISCLAIMER_HEIGHT}rem` : '0'};

  left: 0;

  width: 100%;

  min-height: 6.6rem;

  padding: 1.6rem 2.2rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};

  background-color: ${({ theme }) => theme.colors.surface.default};

  z-index: ${({ theme }) => theme.zIndex.high + 1};

  ${({ theme }) => {
    const index = (theme.zIndex.high + 1) as unknown as TemplateStringsArray;

    return theme.media.laptop`
      left: ${DASHBOARD_NAV_WIDTH as unknown as TemplateStringsArray}rem;

      min-height:8.1rem;
      width: calc(100% - ${
        DASHBOARD_NAV_WIDTH as unknown as TemplateStringsArray
      }rem);

      padding: 0rem 1rem;
      
      z-index: ${index};
  `;
  }}

  ${({ theme }) => theme.media.desktop`
      padding: 0rem 4.6rem 0 3rem;
  `}
`;

export const JustifiedContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.media.laptopL`
    width: 109.7rem;
  `}
`;

export const HomePage = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.laptop`
    display:none;
  `};
`;

export const BitnetLogo = styled.img`
  height: 3.2rem;
`;

export const PageNameWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.laptop`
    display:flex;
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.laptop`
    align-items:end;
  `}
`;

type TransactionsWrapperProps = {
  navigationShouldDisappear?: boolean;
};
export const TransactionsWrapper = styled.div<TransactionsWrapperProps>`
  display: none;

  border-radius: ${({ theme }) => theme.borderRadius.highL};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme, navigationShouldDisappear }) => theme.media.laptop`
    display: ${
      (navigationShouldDisappear
        ? 'none'
        : 'flex') as unknown as TemplateStringsArray
    };
    align-items: center;
    margin-right: 0.5rem;
    padding: 0.5rem 0.5rem;
  `}

  ${({ theme, navigationShouldDisappear }) => theme.media.laptopL`
      display: ${
        (navigationShouldDisappear
          ? 'none'
          : 'flex') as unknown as TemplateStringsArray
      };
    align-items: center;
    margin-right: 1rem;
    padding: 0.5rem 1rem;
  `}
`;

export const CustomButton = styled(Button)`
  color: ${({ theme }) => theme.colors.text.interactive};
  background: linear-gradient(180deg, #286af6 0%, #1152db 100%);
  box-shadow: 0px 6px 19px rgba(26, 58, 126, 0.15),
    inset 0px 4px 4px rgba(95, 146, 255, 0.4);

  transition: none;

  ${({ theme }) => {
    const backgroundColor = theme.colors.interactive.brandColorHover;
    return theme.media.laptop`
      &:hover {
        background: none;
        background-color: ${backgroundColor as unknown as TemplateStringsArray}
      }
    `;
  }}

  &:active {
    background: none;
    background-color: ${({ theme }) =>
      theme.colors.interactive.brandColorHover};
    box-shadow: 0px 6px 19px rgba(26, 58, 126, 0.15),
      inset 0px 4px 4px rgba(95, 146, 255, 0.4);
  }

  &:not(:last-child) {
    margin-right: 0.4rem;
  }

  ${({ theme }) => theme.media.laptop`
    width: 16rem;
  `};
  ${({ theme }) => theme.media.laptopL`
    width: 18rem;
    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  `}

  svg {
    margin-left: -0.5rem;
    max-width: 90%;
    max-height: 90%;
  }
`;

export const IconWrapper = styled(CircledIcon)`
  position: relative;
  width: 5rem;
  height: 5rem;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  margin-left: 1rem;
  &:hover {
    border-color: ${({ theme }) => theme.colors.interactive.brandColor};
    background-color: ${({ theme }) => theme.colors.interactive.secondary};
  }

  ${({ theme }) => theme.media.laptop`
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  `}

  ${({ theme }) => theme.media.laptopL`
    &:not(:last-child) {
      margin-right: 1.8rem;
    }
  `}
`;

export const AccButton = styled(TransparentButton)`
  display: none;
  ${IconWrapper} {
    margin-right: 0;
  }
  ${({ theme }) => theme.media.laptop`
    display:flex;
  `}
`;
export const NotificationAlert = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  top: -0.5rem;
  right: -0.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  background-color: ${({ theme }) => theme.colors.interactive.critical};
`;

export const NotificationContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const CustomSocialOuterLink = styled(SocialOuterLink)`
  display: none;
  ${({ theme }) => theme.media.tablet`
    display: flex;
  `}
`;

export const AccountContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const ThemeTogglerWrapper = styled.div`
  margin-left: 1rem;
  display: none;

  ${({ theme }) => theme.media.tablet`
    display: block;
  `}
`;

export const CustomTypography = styled((props) => (
  <Typography {...props} as="h1" />
))`
  display: none;
  ${({ theme }) => theme.media.laptopM`
display:block`}
`;
