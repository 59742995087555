import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => theme.media.laptop`
  max-width: 50rem;
`}
`;

export const TwoFaContainer = styled.div<{ shouldRenderSteps: boolean }>`
  padding: ${({ shouldRenderSteps }) =>
      shouldRenderSteps ? '15.8rem' : '4.8rem'}
    2.2rem 2.2rem 2.2rem;
`;
