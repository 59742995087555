import { useState } from 'react';
import { useTheme } from 'styled-components';

import { Typography, useModal } from '@libs/components';
import { screenSizes } from '@libs/theme';
import { CashTransactionType, FeatureModuleEnum } from '@libs/backend-common';

import { LocationIcon } from 'src/assets';
import { useDimensions, useTranslation } from 'src/hooks';
import { BitnetAddress } from 'src/constants';

import CashContactUs from '../cash-contact-us/CashContactUs';
import LocationModal from '../location-modal/LocationModal';
import { FeatureRenderer } from '../feature-renderer/FeatureRenderer';
import {
  Address,
  AddressBox,
  AddressIcon,
  AddressIconWrapper,
  AddressWrapper,
  ContactUsWrapper,
  ContactUsButton,
  CustomDropdownIcon,
  CashTradeContainer,
  CashTradeHeader,
  DetailsButtonWrapper,
} from './styles';
export const BITNET_ADDRESSES: BitnetAddress[] = [
  {
    country: 'common_georgia',
    city: 'common_tbilisi',
    address: 'common_tbilisi_address',
    phone: '+995 322 050022',
    url: 'https://maps.app.goo.gl/6SuUvGgMYLh8S9jq5',
    email: 'support@bitnet.ge',
    iframeUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2978.1664205408365!2d44.78519310000001!3d41.7169276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404473fb13a46c3b%3A0x6a4d068ec1327d44!2sBitnet!5e0!3m2!1sen!2sge!4v1701180626441!5m2!1sen!2sge',
  },
];

const CashTrade = ({ type }: { type: CashTransactionType }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { width } = useDimensions();
  const { Dialog, openModal, closeModal } = useModal();
  const [selectedLocation, setSelectedLocation] = useState<BitnetAddress>();
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    closeModal();
  };
  const handleClick = () => {
    setIsVisible(true);
  };

  return (
    <FeatureRenderer
      acceptedFeature={[
        type === CashTransactionType.DEPOSIT
          ? FeatureModuleEnum.CASH_DEPOSIT
          : FeatureModuleEnum.CARD_WITHDRAWAL,
      ]}
    >
      <CashTradeContainer>
        <CashTradeHeader>
          <Typography type="headline" fontFamily="primaryMedium">
            {t('deposit_cash_title')}
          </Typography>
        </CashTradeHeader>

        <AddressWrapper>
          {BITNET_ADDRESSES.map((loc, i) => {
            const handleLocationClick = () => {
              openModal();
              setSelectedLocation(loc);
            };

            return (
              <AddressBox key={i}>
                <AddressIconWrapper>
                  <AddressIcon>
                    <LocationIcon />
                  </AddressIcon>
                </AddressIconWrapper>

                <Typography
                  type="subHeadline"
                  fontFamily="primaryBold"
                  color={colors.interactive.brandColor}
                >
                  {t(loc.city) + ', ' + t(loc.country)}
                </Typography>

                <Address
                  type={width < screenSizes.tablet ? 'headline' : 'subHeadline'}
                  fontFamily="primaryMedium"
                >
                  {t(loc.address)}
                </Address>
                <DetailsButtonWrapper onClick={handleLocationClick}>
                  <Typography
                    type="bodyButton"
                    fontFamily="primaryBold"
                    color={colors.interactive.brandColor}
                  >
                    {t('common_details')}
                  </Typography>
                  <CustomDropdownIcon color={colors.interactive.brandColor} />
                </DetailsButtonWrapper>
              </AddressBox>
            );
          })}
        </AddressWrapper>
        <ContactUsWrapper>
          <Typography type="subHeadline" fontFamily="primaryBold">
            {t('deposit_cash_contact_us_question')}
          </Typography>
          <ContactUsButton
            onClick={handleClick}
            title={t('common_contact')}
            size="md"
          />
        </ContactUsWrapper>
        <CashContactUs setIsVisible={setIsVisible} isVisible={isVisible} />
        <Dialog
          leftSpace={26}
          title={t('deposit_cash_address_details')}
          onHeaderButtonClick={handleClose}
        >
          <LocationModal {...selectedLocation} />
        </Dialog>
      </CashTradeContainer>
    </FeatureRenderer>
  );
};

export default CashTrade;
